import { CloseIcon } from '@chakra-ui/icons';
import { Box, Icon, Image } from '@chakra-ui/react';
import { useRouter } from 'next/router';
// import fileSrc from '../../../public/assets/alma_web31.pptx';
import { useEffect, useState } from 'react';

const EspaPopup = () => {
  const [showBtn, setShowBtn] = useState(true);
  const router = useRouter();

  const espaHandler = () => {
    router.push('/espa-amm');
    setShowBtn(false);
  };

  return (
    <>
      {showBtn && (
        <Box
          pos={'fixed'}
          padding={'20px'}
          bg={'white'}
          bottom={'8px'}
          left={'20px'}
          alignItems={'center'}
          justifyContent={'center'}
          cursor={'pointer'}
          transition={'.5s'}
          zIndex={'9999'}
        >
          <Box onClick={() => setShowBtn(false)} className="x icon" position={'absolute'} top={'0px'} right={'10px'}>
            <CloseIcon width={'12px'} height={'12px'} />
          </Box>
          <Box onClick={espaHandler}>
            <Image width={'140px'} height={'70px'} alt="espa info" src={'/assets/espaBanner.jpg'} />
          </Box>
        </Box>
      )}
    </>
  );
};

export default EspaPopup;
