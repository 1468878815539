import axios from 'axios';
import { apiURL } from 'utils/config';
import { getRealLocale } from 'utils/helpers';

export const getPathInfo = (locale, path) => {
  return axios({
    url: `${apiURL}/${getRealLocale(locale)}/api/v1/router`,
    method: 'GET',
    params: {
      path,
    },
  });
};
