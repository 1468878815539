import axios from 'axios';
import { apiURL } from 'utils/config';
import { getRealLocale } from 'utils/helpers';
const url = `${apiURL}`;
import CookieHelper from 'utils/cookie';
import { getAuthorizationHeaders } from './auth';
/**
 * Returns the list of products in your cart
 * @param locale
 * @returns {AxiosPromise}
 */
export const getCart = (locale) => {
  let headers = { 'Commerce-Cart-Token': CookieHelper.load('cartToken') };

  if (CookieHelper.load('auth')) {
    headers = { ...headers, ...getAuthorizationHeaders() };
  }

  return axios({
    url: `${url}/${getRealLocale(locale)}/api/v1/cart`,
    method: 'GET',
    headers: headers,
  });
};
/**
 * Adds items to cart
 * @param locale
 * @param items
 * @returns {AxiosPromise}
 */
export const addToCart = (locale, items) => {
  let headers = { 'Commerce-Cart-Token': CookieHelper.load('cartToken') };

  if (CookieHelper.load('auth')) {
    headers = { ...headers, ...getAuthorizationHeaders() };
  }
  return axios({
    url: `${url}/${getRealLocale(locale)}/api/v1/cart/add`,
    method: 'POST',
    data: items,
    headers: headers,
  });
};

/**
 * Update cart item quantity
 * @param locale
 * @param orderId
 * @param orderItemId
 * @param quantity
 * @returns {AxiosPromise}
 */
export const updateQuantity = (locale, orderId, orderItemId, quantity) => {
  let headers = { 'Commerce-Cart-Token': CookieHelper.load('cartToken') };

  if (CookieHelper.load('auth')) {
    headers = { ...headers, ...getAuthorizationHeaders() };
  }
  return axios({
    url: `${url}/${getRealLocale(locale)}/api/v1/cart/${orderId}/items/${orderItemId}`,
    method: 'PATCH',
    data: { quantity },
    headers: headers,
  });
};

/**
 * Delete cart item
 * @param locale
 * @param orderId
 * @param orderItemId
 * @returns {AxiosPromise}
 */
export const deleteItem = (locale, orderId, orderItemId) => {
  let headers = { 'Commerce-Cart-Token': CookieHelper.load('cartToken') };

  if (CookieHelper.load('auth')) {
    headers = { ...headers, ...getAuthorizationHeaders() };
  }

  return axios({
    url: `${url}/${getRealLocale(locale)}/api/v1/cart/${orderId}/items/${orderItemId}`,
    method: 'DELETE',
    headers: headers,
  });
};
