//import Image from 'next/image';
import { Link, Image } from '@chakra-ui/react';
import NextLink from 'next/link';

const Logo = (props) => {
  return (
    <>
      <NextLink href={'/'} passHref prefetch={false}>
        <Link
          {...props}
          width={{ base: '140px', sm: '155px', md: '187px' }}
          display={'flex'}
          justifyContent={'center'}
          boxShadow={'none !important'}
        >
          <Image
            // w={'187px'}
            h={'69px'}
            src={'/assets/amm-logo-white.png'}
            width={{ base: '140px', sm: '155px', md: '187px' }}
            height={{ base: 'auto', sm: 69 }}
            alt={'Home page'}
          />
        </Link>
      </NextLink>
    </>
  );
};

export default Logo;
