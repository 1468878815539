import { Menu, MenuButton, MenuList, MenuItem, Text, Button } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { LANGUAGES } from 'utils/constants';
import { getPathInfo } from 'services/router';
import { getPathProps } from 'services/paths';

const LanguageSelect = ({ color }) => {
  const router = useRouter();
  const { lang } = useTranslation();
  const selectedLang = LANGUAGES.find((i) => i.id === lang);

  const handleLanguageChange = async (loc) => {
    try {
      const { data: pageProps } = await getPathProps(router.locale, router.asPath);

      let path;

      switch (pageProps.type) {
        case 'commerce_product':
          path = `/product/${pageProps.id}`;
          break;
        case 'taxonomy_term':
          path = `/taxonomy/term/${pageProps.id}`;
          break;
        case 'node':
          path = `/node/${pageProps.id}`;
          break;
        default:
          router.push(router.asPath, router.asPath, { locale: loc });
          break;
      }
      const { data } = await getPathInfo(loc, path);
      router.push(data.path, data.path, { locale: false });
    } catch (err) {
      router.push(router.asPath, router.asPath, { locale: loc });
    } finally {
      localStorage.setItem('removeFilters', true);
    }
  };

  return (
    <>
      <Menu>
        <MenuButton
          display={'flex'}
          flexDirection={'row'}
          outline={'none'}
          boxShadow={'none'}
          _focus={{
            boxShadow: 'none',
          }}
          variant={'unstyled'}
          h="30px"
          mr={{ base: '0', md: '26px' }}
        >
          <Text color={color ? color : 'white'} fontWeight={'bold'}>
            {selectedLang?.label || 'GR'}
          </Text>
        </MenuButton>
        <MenuList>
          {LANGUAGES.map((i) => {
            return (
              <MenuItem
                key={i.id}
                onClick={() => {
                  handleLanguageChange(i.id);
                }}
              >
                {i.label}
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </>
  );
};

export default LanguageSelect;
