import React, { useState, useEffect, memo } from 'react';
import { Box } from '@chakra-ui/react';
import Logo from '../Logo';
import MobileUserNav from './MobileUserNav';
import LevelOneMobileMenu from './LevelOneMobileMenu';
import Burger from 'components/icons/Burger';
import { useRouter } from 'next/router';
import Notification from '../Menu/Notification';
import { getMenu as apiCallToFetchMenu } from 'services/menu';

const HeaderMobile = ({ customHeight, menuAndFooterData }) => {
  const [isActive, setIsActive] = useState(false);
  const [isThird, setIsThird] = useState(false);

  const router = useRouter();
  const [menuData, setMenuData] = useState(null);

  useEffect(() => {
    apiCallToFetchMenu(router.locale).then((res) => {
      setMenuData(res.data);
    });
  }, [router.locale]);

  useEffect(() => {
    setIsActive(false);
    document.body.style.overflow = 'auto';
  }, [router.asPath]);

  const toggleButton = () => {
    if (!isActive) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    setIsActive(!isActive);
  };

  const thisPath = router.asPath.substr(router.asPath.lastIndexOf('/') + 1);

  return (
    <>
      <Box
        as={'header'}
        position={'sticky'}
        top={'0'}
        zIndex={'1000'}
        background={'brand.900'}
        height={'90px'}
        px="12px"
      >
        <Box alignItems={'center'} justifyContent="center" display={'flex'} flexDir="column" height={'90px'}>
          <Box display={'flex'} w={'100%'}>
            <Box textAlign={'left'} flex={1}>
              <Burger isActive={isActive} toggleButton={toggleButton} />
            </Box>
            <Logo />
            <MobileUserNav />
          </Box>
        </Box>
        {menuAndFooterData?.map(({ position, sestions }, index) =>
          position && position === 'below_menu' ? (
            <Notification data={sestions[0]?.data?.text} key={`belowData-${index}`} mobile={true} />
          ) : null
        )}

        {isActive ? (
          <LevelOneMobileMenu
            setIsThird={setIsThird}
            menuData={menuData}
            customHeight={customHeight}
            thisPath={thisPath}
          />
        ) : null}
      </Box>
    </>
  );
};

export default memo(HeaderMobile);
