import { ListItem, Link } from '@chakra-ui/react';
import NextLink from 'next/link';

const ItemList = ({ title, relative }) => {
  return title ? (
    <ListItem fontSize="16px" lineHeight="20px" paddingBottom="18px">
      <NextLink passHref href={relative} prefetch={false}>
        <Link>{title}</Link>
      </NextLink>
    </ListItem>
  ) : null;
};

export default ItemList;
