import { memo, useEffect, useState } from 'react';
import { Popover, PopoverContent, Box, PopoverCloseButton, Text } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import useUser from 'hooks/useUser';
import Heart from 'components/icons/Heart';
import LoginForm from 'components/common/UserProfile/LoginForm';
import useWishlist from 'hooks/useWishlist';

export const WishListIndicator = ({ boxSize }) => {
  const router = useRouter();
  const [favoriteOpen, setFavoriteOpen] = useState(false);
  const closeFavorite = () => setFavoriteOpen(false);
  const openFavorite = () => setFavoriteOpen(true);
  const { wishListData } = useWishlist();
  const { userData } = useUser();

  const handleClickForLoggedInUsers = () => router.push('/wishlist');

  useEffect(() => {
    setFavoriteOpen(false);
  }, [router.asPath]);

  return (
    <Box display={'flex'} mr={'15px'} _hover={{ cursor: 'pointer' }} position={'relative'}>
      <>
        <Heart onClick={handleClickForLoggedInUsers} boxSize={boxSize ? boxSize : null} color={'white'} />
        {wishListData && wishListData.length > 0 && (
          <Box
            onClick={handleClickForLoggedInUsers}
            className={'ammMiniCart__indicator'}
            bg={'black'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            borderRadius={'50%'}
            position={'absolute'}
            bottom={'-8px'}
            right={'-8px'}
            padding={'3px'}
            w={'18px'}
            h={'18px'}
            lineHeight={1}
          >
            <Text fontSize={'10px'} fontWeight={'bold'} color={'white'} lineHeight={1}>
              {(wishListData && wishListData.length) || 0}
            </Text>
          </Box>
        )}{' '}
      </>
    </Box>
  );
};

export default memo(WishListIndicator);
