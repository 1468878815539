import { Box } from '@chakra-ui/react';

const Notification = ({ data, mobile }) => {
  function createDescriptionMarkup() {
    return { __html: data };
  }
  return (
    <>
      <Box
        as={'div'}
        display="flex"
        justifyContent="center"
        alignItems="center"
        background={'black'}
        height={'auto'}
        id="jsAmmNotification"
        pb={'1px'}
        width={'100%'}
        pos={mobile ? 'absolute' : 'relative'}
        left={mobile ? '0' : 'unset'}
        zIndex={'-1'}
      >
        <Box
          align={'center'}
          fontWeight={'bold'}
          color={'white'}
          fontSize={{ base: '9px', sm: '16px' }}
          dangerouslySetInnerHTML={createDescriptionMarkup()}
        ></Box>
      </Box>
    </>
  );
};

export default Notification;
