import { Icon } from '@chakra-ui/react';

const User = (props) => (
  <Icon viewBox="0 0 17 18" {...props}>
    <path
      d="M8.34391 9.63894C5.78037 9.63894 3.69629 7.56072 3.69629 5.0044C3.69629 2.44807 5.78037 0.373047 8.34391 0.373047C10.9074 0.373047 12.9915 2.45126 12.9915 5.00758C12.9915 7.56391 10.9043 9.63894 8.34391 9.63894ZM8.34391 1.73408C6.53472 1.73408 5.06436 3.20031 5.06436 5.0044C5.06436 6.80849 6.53472 8.2779 8.34391 8.2779C10.1531 8.2779 11.6234 6.81168 11.6234 5.00758C11.6234 3.20349 10.1531 1.73408 8.34391 1.73408Z"
      fill={'white'}
    />
    <path
      d="M15.8234 17.3746C15.3983 17.3746 15.0531 17.0303 15.0531 16.6064C15.0531 12.9154 12.042 9.91597 8.34375 9.91597C4.64547 9.91597 1.63124 12.9185 1.63124 16.6096C1.63124 17.0335 1.28602 17.3778 0.860895 17.3778C0.435769 17.3778 0.09375 17.0335 0.09375 16.6096C0.09375 14.4134 0.950395 12.348 2.51026 10.7925C4.07012 9.23705 6.14141 8.38281 8.34375 8.38281C10.5461 8.38281 12.6174 9.23705 14.1772 10.7925C15.7371 12.348 16.5938 14.4134 16.5938 16.6096C16.5906 17.0335 16.2485 17.3746 15.8234 17.3746Z"
      fill={'white'}
    />
  </Icon>
);

export default User;
