import { Box, Link } from '@chakra-ui/react';
import User from 'components/icons/User';
import NextLink from 'next/link';
import MiniCart from '../MiniCart';
import GlobalSearch from 'components/common/GlobalSearch';
import { useRouter } from 'next/router';

const MobileUserNav = () => {
  const router = useRouter();
  return (
    <>
      <Box
        as={'nav'}
        className="cart-box-parent-mobile"
        flex={1}
        display={'flex'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        mt={'8px'}
        pos="relative"
      >
        <Box
          _hover={{ cursor: 'pointer' }}
          onClick={() => router.push('/user')}
          title={'User'}
          display={'flex'}
          ml={{ base: '7px', lg: '15px' }}
          mr={{ base: '7px', lg: '15px' }}
        >
          <User boxSize={{ base: '16px', sm: '24px' }} />
        </Box>

        <MiniCart boxSize={{ base: '16px', sm: '24px' }} />
        <GlobalSearch />
      </Box>
    </>
  );
};

export default MobileUserNav;
