import { memo } from 'react';

import { Box } from '@chakra-ui/react';
import User from 'components/icons/User';
import MiniCart from '../MiniCart';
import WishListIndicator from '../WishListIndicator';
import { useRouter } from 'next/router';

const IconMenu = ({ theColor }) => {
  const router = useRouter();
  return (
    <Box display="flex" className="icons cart-box-parent" pos="relative">
      <Box
        _hover={{ cursor: 'pointer' }}
        onClick={() => router.push('/user')}
        title={'User'}
        display={'flex'}
        mr={'15px'}
      >
        <User color={theColor} />
      </Box>

      <MiniCart color={theColor} />
      <WishListIndicator />
    </Box>
  );
};

export default memo(IconMenu);
