import { addMinutes, format, set, subMinutes, fromUnixTime } from 'date-fns';

import el from 'date-fns/locale/el';
import fr from 'date-fns/locale/fr';

/**
 * Gets the error message from a failed API call
 * @param error
 * @returns {*}
 */
export function getErrorMessage(error) {
  let errorMsg = 'Some error happened. Please try again later.';

  if (typeof error === 'string') {
    errorMsg = error;
  } else if (error && error.response && error.response.data && error.response.data.error_description) {
    errorMsg = error.response.data.error_description;
  } else if (error && error.response && error.response.data && error.response.data.message) {
    errorMsg = error.response.data.message;
  } else if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.errors &&
    error.response.data.errors.length > 0
  ) {
    errorMsg = '';
    error.response.data.errors.map((i) => {
      errorMsg += `${i.field}: ${i.message} \n\n`;
      return i;
    });
    //errorMsg = error.response.data.errors[0].message;
  } else if (error && error.response && error.response.data && error.response.data.error) {
    if (error && error.response && error.response.data && typeof error.response.data.error === 'string') {
      errorMsg = error.response.data.error;
    } else {
      errorMsg =
        error && error.response && error.response.data && error.response.data.error
          ? error.response.data.error.message
          : 'Some error happened. Please try again later.';
    }
  } else if (error && error.message) {
    errorMsg = error.message;
  } else {
    errorMsg =
      error && error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : 'Some error happened. Please try again later.';
  }
  return errorMsg;
}

export function getTimeWithTimezoneOffset(time) {
  if (!time) return null;

  const timeArr = time.split(':');
  const dateFromTime = set(new Date(), { hours: parseInt(timeArr[0]), minutes: parseInt(timeArr[1]) });
  // console.log("dateFromTime", dateFromTime);

  const timeOffset = new Date().getTimezoneOffset();
  //console.log("timeOffset", timeOffset);

  return format(subMinutes(dateFromTime, timeOffset), 'HH:mm');
}

export function getTimeWithoutTimezoneOffset(time) {
  if (!time) return null;

  const timeArr = time.split(':');
  const dateFromTime = set(new Date(), { hours: parseInt(timeArr[0]), minutes: parseInt(timeArr[1]) });
  //console.log('dateFromTime', dateFromTime);

  const timeOffset = new Date().getTimezoneOffset();
  //console.log('timeOffset', timeOffset);

  //const dateWithoutOffset = format(addMinutes(dateFromTime, timeOffset), 'HH:mm');
  //console.log('dateWithoutOffset', dateWithoutOffset);
  return format(addMinutes(dateFromTime, timeOffset), 'HH:mm');
}

export function formatPrice(price) {
  return new Intl.NumberFormat('gr-GR', {
    style: 'currency',
    currency: 'EUR',
  }).format(price);
}

export function formatKlarnaPrice(price) {
  let result = price.toString().replace(/[,.€]/g, '');
  console.log(result, 'result');
  return result;
}

//This is useful cause of the "fakeLocale" we use
export function getRealLocale(locale) {
  const FAKE_LOCALE = 'my';
  const DEFAULT_LOCALE = 'el';
  return locale === FAKE_LOCALE ? DEFAULT_LOCALE : locale;
}

export function getFormattedFilters(filters) {
  let array = [];

  for (let i in filters) {
    array = array.concat(filters[i]);
  }

  return array;
}

export function createFiltersParamsUrl(selectedFilters) {
  let selectedFiltersPath = '';
  //console.log('selectedFilters', selectedFilters);
  let filtersArr = [];

  for (let i in selectedFilters) {
    if (selectedFilters[i] && selectedFilters[i].length > 0) {
      filtersArr = filtersArr.concat(selectedFilters[i]);
    }
  }

  /*  console.log(
    'filtersArr',
    filtersArr.sort((a, b) => a.filterSearchOrder - b.filterSearchOrder)
  );*/

  const valuesArr = filtersArr
    .sort((a, b) => a.filterSearchOrder - b.filterSearchOrder)
    .map((f) => {
      return f.filter_transliterated;
    });
  selectedFiltersPath += `${valuesArr.join('-')}-`;

  return selectedFiltersPath.slice(0, selectedFiltersPath.length - 1);
}

export function createFiltersFromUrl(url) {
  if (!url || url.indexOf('?') === -1) {
    return;
  }

  let urlParams = decodeURIComponent(url).slice(url.indexOf('?') + 1, url.length);
  let paramsArr = urlParams.split('&');
  let formattedParams = {};

  for (let i = 0; i < paramsArr.length; i++) {
    let item = paramsArr[i].split('=');
    formattedParams[item[0]] = item[1].split(',');
  }
  return formattedParams;
}

export function createFiltersFromQuery(query, facets) {
  if (!query || !facets || facets.length === 0) {
    return;
  }
  let selectedFilters = {};

  const filtersArr = query.split('-').map((i) => i.slice(0, i.length));

  facets.map((i) => {
    for (let f = 0; f < i.filters.length; f++) {
      for (let a = 0; a < filtersArr.length; a++) {
        if (filtersArr[a] === i.filters[f].filter_transliterated) {
          if (!selectedFilters[i.facet]) {
            selectedFilters[i.facet] = [i.filters[f]];
          } else {
            selectedFilters[i.facet].push(i.filters[f]);
          }
        }
      }
    }
    return i;
  });

  return selectedFilters;
}

export function formatFacets(facets) {
  if (!facets) return;

  let transliteratedNames = [];

  return facets.map((i, idx) => {
    return {
      ...i,
      facetSearchOrder: idx,
      filters: i.filters.map((j, jIdx) => {
        let transliteratedName = generateUniqueTransliteratedFilterName(j.filter_transliterated, transliteratedNames);
        transliteratedNames.push(transliteratedName);

        return {
          ...j,
          facet: i.facet,
          filter_transliterated: transliteratedName,
          filterSearchOrder: idx * 10 + jIdx,
          uuid: `${i.facet}_${transliteratedName}`,
        };
      }),
    };
  });
}

function generateUniqueTransliteratedFilterName(filterName, transliteratedNames) {
  const occurrences = transliteratedNames.filter((i) => i === filterName).length;
  let name = filterName;

  for (let i = 0; i < occurrences; i++) {
    name = name + '_';
  }

  return name;
}

export const formatTimestampBasedOnLocale = (timestamp, locale) => {
  switch (locale) {
    case 'en':
      return format(fromUnixTime(timestamp), 'dd MMM, yyyy');
    case 'el':
      return format(fromUnixTime(timestamp), 'dd MMM, yyyy', {
        locale: el,
      });
    case 'fr':
      return format(fromUnixTime(timestamp), 'dd MMM, yyyy', {
        locale: fr,
      });

    default:
      return format(fromUnixTime(timestamp), 'dd MMM, yyyy');
  }
};

export function scrollToElement(elementID, offset, useOffsetOnlyOnDesktop) {
  const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  let isDesktop = windowWidth > 1100;
  const yOffset = useOffsetOnlyOnDesktop ? (isDesktop ? offset : 0) : offset || 0;
  const element = document.getElementById(elementID);

  if (!element) return;

  const topPos = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

  window.scrollTo({ top: topPos, behavior: 'smooth' });
}

/*
export function cleanUpEmptyFields (originalObj) {
  if(!originalObj) return null

  let formattedData = {};

  for(let i in originalObj) {
    if(originalObj[i] === "") {
      formattedData[i] = null
    } else {
      formattedData[i] =
    }
  }

  return {...formattedData}

}
*/

export function getCategoriesFromBreadcrumbs(breadcrumbs) {
  if (!breadcrumbs || breadcrumbs?.length <= 1) return [];

  return breadcrumbs?.filter?.((i, index) => index !== 0 && index !== breadcrumbs.length - 1)?.map((i) => i.name);
}

export function generateUniqueId() {
  return `id-${Math.random().toString(36).substr(2, 9)}`;
}
