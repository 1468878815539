import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import {
  Box,
  UnorderedList,
  ListItem,
  Grid,
  GridItem,
  Button,
  Text,
  Link,
  Image,
  useDisclosure,
  useToast,
  FormLabel,
} from '@chakra-ui/react';
import FormInput from 'components/common/Forms/FormInput';
import { useForm } from 'react-hook-form';
import ItemList from './ItemList';
import SocialIconList from './SocialIconList';
import useTranslation from 'next-translate/useTranslation';
import SelectCountry from 'components/common/NewsletterModal/SelectCountry';
import { getFooterAbout, getFooterHelp } from 'services/footer';
import { subscribe } from 'services/newsletter';
import { getErrorMessage } from 'utils/helpers';

export default function Footer({}) {
  const router = useRouter();
  const { t } = useTranslation('common');
  const [footerDataAbout, setFooterDataAbout] = useState([]);
  const [footerDataHelp, setFooterDataHelp] = useState([]);
  const [newsletterData, setNewsletterData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getFooterAbout(router.locale).then((res) => {
      setFooterDataAbout(res);
    });
    getFooterHelp(router.locale).then((res) => {
      setFooterDataHelp(res);
    });
  }, [router.locale]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({});

  const toast = useToast();

  const isTermsChecked = watch('consent_terms');

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const response = await subscribe(router.locale, { ...data });
      setNewsletterData(response.data);
      toast({
        title: t('user:success'),
        description: t('form:newletterSuccess'),
        position: 'top',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (err) {
      console.log(err);
      toast({
        description: getErrorMessage(err),
        position: 'top',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const { isOpen, onClose } = useDisclosure();

  const socialLinks = [
    {
      socialIconUrl: '/assets/fb.png',
      socialIconAlt: 'Join us on Facebook!',
      url: 'https://www.facebook.com/annamariamazaraki',
    },
    {
      socialIconUrl: '/assets/insta.png',
      socialIconAlt: 'Our instagram channel',
      url: 'https://www.instagram.com/annamariamazaraki/',
    },
    {
      socialIconUrl: '/assets/youtube.png',
      socialIconAlt: 'Our YouTube channel',
      url: 'https://www.youtube.com/channel/UCvXOo0-dHVvn6N__vWAdftA',
    },
    {
      socialIconUrl: '/assets/pinterest.png',
      socialIconAlt: 'Our Pinterest channel',
      url: 'https://gr.pinterest.com/annamariamazaraki/',
    },
    {
      socialIconUrl: '/assets/in.png',
      socialIconAlt: 'Our LinkedIn channel',
      url: 'https://www.linkedin.com/company/anna-maria-mazaraki-s-a-',
    },
    {
      socialIconUrl: '/assets/tiktok.png',
      socialIconAlt: 'Our TikTok channel',
      url: 'https://www.tiktok.com/@annamariamazaraki',
    },
  ];

  return (
    <Box
      as={'footer'}
      w="100%"
      borderTopWidth={'1px'}
      borderBottomWidth={'1px'}
      borderColor={'brand.900'}
      display="inline-block"
    >
      <Grid templateColumns={{ base: 'inherit', lg: 'repeat(12, 1fr)' }}>
        <GridItem
          colSpan={{ base: 7, lg: 5 }}
          background={'brand.900'}
          h={{ base: 'auto' }}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'space-around'}
          textAlign={'center'}
          padding={'10px 30px'}
        >
          <Box as="h2" fontSize="36px" lineHeight="45px" color="white">
            {t('joinFooter')}
          </Box>
          <Box as="p" fontSize="16px" lineHeight="px" color="white" padding={{ base: '20px 0', lg: '0' }}>
            {t('common:newsletterTextMain')}
          </Box>
          <Box
            as={'form'}
            width={{ base: '75%', lg: '85%' }}
            onSubmit={handleSubmit(onSubmit)}
            className={'newsletter-validation'}
          >
            <FormInput
              inputProps={{
                type: 'email',
                ...register('email', {
                  required: 'required field',
                  pattern: {
                    value:
                      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                    message: 'please provide a valid email', // JS only: <p>error message</p> TS only support string
                  },
                }),
              }}
              width={{ base: '75%', lg: '55%' }}
              backgroundColor={{ base: 'transparent', md: '#ffffff' }}
              borderRadius={'0px'}
              textAlign={'center'}
              placeholder={t('email')}
              marginBottom={'20px'}
              border={'none'}
              label={'E-mail:'}
              id="email footer"
              labelProps={{ color: 'white' }}
              borderBottom={{ base: '1px solid #ffffff', md: 'none' }}
              error={errors?.email?.message}
            />
            <Box mt="20px" mb="30px">
              <Text fontSize="24px" color="brand.900" />
              <Box color={'white'}>
                {/*  <FormCheckBox
                  label={t('secondOptionConsent')}
                  inputProps={{ ...register('consent_terms') }}
                  id="consent_terms"
                  error={errors?.consent_terms?.message}
                />*/}
                <Box display={'flex'}>
                  {/*  <Checkbox {...register('consent_terms')} id={'newsletter-terms'} />*/}

                  <input
                    type={'checkbox'}
                    {...register('consent_terms')}
                    id={'consent_terms'}
                    style={{ marginRight: '16px' }}
                  />

                  <FormLabel htmlFor={'consent_terms'}>{t('secondOptionConsent')}</FormLabel>
                </Box>
              </Box>
            </Box>
            <Button
              isDisabled={!isTermsChecked || errors?.email}
              type={'submit'}
              w={'250px'}
              display={'flex'}
              variant={'outlineInverted'}
              m={'auto'}
            >
              {t('signupnow')}
            </Button>
          </Box>

          <SelectCountry isOpen={isOpen} onClose={onClose} />
        </GridItem>
        <GridItem padding={'30px 0'} colSpan={7}>
          <Grid templateColumns={{ base: 'inherit', sm: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)', lg: 'repeat(3, 1fr)' }}>
            <GridItem colSpan={1} paddingLeft={{ base: '0', sm: '25px' }} textAlign={{ base: 'center', sm: 'left' }}>
              <Box as="p" textStyle="footerH4" paddingBottom="15px">
                {t('footerMenuTitle')}
              </Box>
              <UnorderedList listStyleType="none" marginLeft="0" className="social-icon">
                {footerDataHelp?.data?.map(({ title, relative }, index) => (
                  <ItemList title={title} relative={relative} key={`help-${index}`} />
                ))}
              </UnorderedList>
            </GridItem>
            <GridItem colSpan={1} textAlign={{ base: 'center', sm: 'left' }}>
              <Box as="p" textStyle="footerH4" paddingBottom="15px">
                {t('footerMenuTitleAbout')}
              </Box>
              <UnorderedList listStyleType="none" marginLeft="0">
                {footerDataAbout?.data?.map(({ title, relative }, index) => (
                  <ItemList title={title} relative={relative} key={`about-${index}`} />
                ))}
              </UnorderedList>
            </GridItem>
            <Box>
              <Box paddingLeft={{ base: '0', sm: '25px', md: '0' }} paddingTop={{ base: '25px', md: '0' }}>
                {router.locale === 'en' ? (
                  <Box as="p" textStyle="footerH4" paddingBottom="15px" textAlign={{ base: 'center', sm: 'left' }}>
                    {t('letsSocial')}
                  </Box>
                ) : (
                  ''
                )}

                <UnorderedList
                  listStyleType="none"
                  display="flex"
                  marginLeft="0"
                  justifyContent={{ base: 'center', sm: 'unset' }}
                >
                  {socialLinks.map(({ url, socialIconUrl, socialIconAlt }, index) => (
                    <SocialIconList url={url} imageUrl={socialIconUrl} alt={socialIconAlt} key={`social-${index}`} />
                  ))}
                  <ListItem
                    display={{ base: 'block', md: 'none' }}
                    marginRight={{ base: '4px', sm: '20px' }}
                    marginLeft={{ base: '4px', md: '0' }}
                  >
                    <Link>
                      <Image src="/assets/messenger.png" alt="messenger-icon" maxW="inherit" width={35} height={35} />
                    </Link>
                  </ListItem>
                </UnorderedList>
              </Box>
              <Box display={{ base: 'none', md: 'flex' }} alignItems="center" marginTop="20px" marginBottom="25px">
                <Box as="p" textStyle="footerH4" marginRight="20px">
                  {t('letsChat')}
                </Box>
                <Link>
                  <Image
                    src="/assets/messenger.png"
                    alt="chat with us"
                    width={{ base: '42px !important', md: '42px !important' }}
                    height={{ base: '42px !important', md: '42px !important' }}
                    objectFit="cover"
                  />
                </Link>
              </Box>
              <Box display={{ base: 'none', md: 'flex' }}>
                <Link>
                  <Image src="/assets/logo.svg" alt="Anna MAria Mazaraki-logo footer" width={225} height={110} />
                </Link>
              </Box>
              <Box
                onClick={() => router.push('/espa-amm')}
                cursor={'pointer'}
                mt="10px"
                display={'flex'}
                justifyContent={{ base: 'center', lg: 'flex-start' }}
              >
                <Image src="/assets/espaBanner.jpg" alt="espa-logo footer" width={170} height={'80px'} />
              </Box>
            </Box>
          </Grid>
          <Box textAlign={{ base: 'center', lg: 'right' }} paddingTop={'10px'}>
            <Box as="p" fontSize="12px" lineHeight="15px" pr={'20px'}>
              {t('copyright')}
            </Box>
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
}
