import { useEffect, useState, memo } from 'react';
import Script from 'next/script';
import { useBreakpointValue } from '@chakra-ui/react';
import Header from 'components/common/Header';
import HeaderMobile from 'components/common/HeaderMobile';
import Menu from 'components/common/Menu';
import Footer from 'components/common/Footer';
import { useRouter } from 'next/router';
import { getdataForMenuAndFooter } from 'services/dataForMenuAndFooter';
import InfoIcons from 'components/common/InfoIcons';
import EspaPopup from 'components/common/EspaPopup';
import useCart from 'hooks/useCart';
import { Box } from '@chakra-ui/react';

const MainLayout = ({ children }) => {
  const router = useRouter();
  const [mainContentMenuMobile, setMainContentMenuMobile] = useState(90);
  const [screenHeight, setScreenHeight] = useState();
  const [innerHeight, setInnerHeight] = useState();
  const [menuAndFooterData, setmenuAndFooterData] = useState(null);
  const { cartData } = useCart();

  useEffect(() => {
    setScreenHeight(screenHeight - window.innerHeight);
    setScreenHeight(window.innerHeight);
  }, []);

  useEffect(() => {
    setMainContentMenuMobile(screenHeight - 306);
  }, [screenHeight]);

  useEffect(() => {
    setScreenHeight(window.innerHeight);
  }, []);

  useEffect(() => {
    getdataForMenuAndFooter(router.locale).then((res) => {
      setmenuAndFooterData(res.data);
    });
  }, [router.locale]);

  const [iconMenuShow, setIconMenuShow] = useState(false);

  const handleScroll = (ev) => {
    const menu = document.getElementById('jsAmmMenu');
    const headerTopMenu = document.getElementById('jsAmmHeader');
    const notice = document.getElementById('jsAmmNotification');
    const headerBottomOnScroll = headerTopMenu?.getBoundingClientRect().bottom;

    if (headerBottomOnScroll < 0) {
      setIconMenuShow(true);
    } else {
      setIconMenuShow(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Function to check if Basket is available globally
    const checkBasketAvailability = () => {
      if ('Basket' in window) {
        return true;
      }
    };

    if (checkBasketAvailability()) {
      const scriptContent = `
        var b = new Basket();
        b.basket_update();
      `;

      const scriptElement = document.createElement('script');
      scriptElement.type = 'text/javascript';
      scriptElement.innerHTML = scriptContent;
      document.body.appendChild(scriptElement);

      return () => {
        // Cleanup the script when the component unmounts
        document.body.removeChild(scriptElement);
      };
    }
  }, [cartData]);

  const header = useBreakpointValue({
    base: <HeaderMobile customHeight={mainContentMenuMobile} menuAndFooterData={menuAndFooterData} />,
    sm: <HeaderMobile customHeight={mainContentMenuMobile} menuAndFooterData={menuAndFooterData} />,
    md: <HeaderMobile customHeight={mainContentMenuMobile} menuAndFooterData={menuAndFooterData} />,
    lg: <Header iconMenuShow={!iconMenuShow} menuAndFooterData={menuAndFooterData} />,
    xl: <Header iconMenuShow={!iconMenuShow} menuAndFooterData={menuAndFooterData} />,
  });
  return (
    <>
      {header}
      {!router.asPath.includes('checkout/success') && (
        <Box className={'scibyl_cart'} display={'none'}>
          {cartData?.order_items?.map((item, index) => {
            return (
              <Box key={index} className="scibyl_cart_item">
                <span class="product_id">{item.sku}</span>
                <span class="quantity">{item.quantity}</span>
                <span class="unit_price">{item.unit_price}</span>
                <span class="currency_code">EUR</span>
                <span class="size">{item.size}</span>
              </Box>
            );
          })}
        </Box>
      )}
      {children}

      {menuAndFooterData?.map(({ position, sestions }, index) =>
        position && position === 'above_footer' ? (
          <InfoIcons data={sestions[0]?.data?.info} key={`InfoIcons-${index}`} />
        ) : null
      )}

      <Footer />

      {process.env.NODE_ENV === 'development' ? null : (
        <Script
          id="omnisend-js"
          strategy="afterInteractive"
          onLoad={() => {
            // console.log("omnisend loaded")
          }}
        >
          {`
          //OMNISEND-SNIPPET-SOURCE-CODE-V1
          window.omnisend = window.omnisend || [];
          omnisend.push(['accountID', '5bdff8d38653ed3b5e4b9d9a']);
          omnisend.push(['track', '$pageViewed']);
          !function(){var e=document.createElement("script");e.type="text/javascript",e.async=!0,e.src="https://omnisnippet1.com/inshop/launcher-v2.js";var t=document.getElementsByTagName("script")[0];t.parentNode.insertBefore(e,t)}();
        `}
        </Script>
      )}
    </>
  );
};

export default memo(MainLayout);
