import TagManager from 'react-gtm-module';
import axios from 'axios';
import { getCategoriesFromBreadcrumbs } from './helpers';
const tagManagerArgs = {
  gtmId: 'GTM-W9R8MD6',
};

const url = 'https://www.annamariamazaraki.gr';

function pushMetriloEvent(eventName, args, params) {
  (function () {
    // console.log('>>>>pushMetriloEvent', eventName, args, params);
    const f = function () {
      window.metrilo[eventName](args, params);
    };
    if (typeof window.metrilo !== 'undefined' && typeof window.metrilo[eventName] !== 'undefined') {
      f();
    } else {
      window.metriloQueue = window.metriloQueue || [];
      window.metriloQueue.push(f);
    }
  })();
}

export const Tracking = () => {
  function findCreative(creative) {
    switch (creative) {
      case 'hero_banner':
        return 'Hero Top';
      case 'banner_text':
        return 'banner';
      case 'content_grid':
        return '3 Cards Mid';
      default:
        return 'promotion';
    }
  }

  function initialize() {
    // if( process.env.NODE_ENV === 'development') {return}

    TagManager.initialize(tagManagerArgs);
  }

  const generateItem = (data, overrides) => {
    const categories = getCategoriesFromBreadcrumbs(data?.breadcrumbs);
    let defaultVariation = {};

    if (data?.variations) {
      defaultVariation = data?.variations?.find((i) => i.id === data?.default_variation_id);
    }

    if (data?.teaser_variation_options) {
      defaultVariation = data?.teaser_variation_options?.find((i) => i.id === data?.default_variation_id);
    }

    if (!defaultVariation?.price_raw) {
      defaultVariation = { ...defaultVariation, price_raw: data?.price_raw, list_price_raw: data?.list_price_raw };
    }
    return {
      id: data?.product_id,
      item_id: data?.product_id?.split('-')[0],
      item_name: data?.title || data?.product_title,
      title: data?.title || data?.product_title,
      affiliation: 'Online eShop',
      currency: 'EUR',
      discount: defaultVariation?.list_price_raw
        ? +(defaultVariation.list_price_raw - defaultVariation.price_raw).toFixed(2)
        : 0,
      index: 0,
      item_brand: data?.brand?.name || data?.brand,
      item_category: categories?.[0],
      item_category2: categories?.[1],
      item_category3: categories?.[2],
      item_category4: categories?.[3],
      item_category5: categories?.[4],
      price: defaultVariation?.price_raw,
      item_price: defaultVariation?.price_raw,
      quantity: 1,
      google_business_vertical: 'retail',
      ...overrides,
    };
  };

  function articleView(id, name) {
    pushMetriloEvent('viewArticle', id, { name, url: window.location.href });
  }

  function identifyCustomer(email) {
    pushMetriloEvent('identify', email);
  }

  function customerDetails(params) {
    pushMetriloEvent('customerDetails', params);
  }

  function pageView(pageType, locale, path, page_query, page_term, user, title, overrides, metriloConfig) {
    //  console.log('>>>pageView', pageType, title, overrides, metriloConfig);
    TagManager.dataLayer({
      dataLayer: {
        // Custom Dimensions outside EEC and with pageview
        event: 'page_view', // GTM trigger to pull data from dataLayer
        page_type: pageType, // Values: Homepage | Category | Product | Cart | Checkout | Purchase | Stores | Help | About | 404 | Other (Values based on page type)
        site_lang: locale,
        page_path: path,
        full_URL: window.location.href,
        search_term: page_term || undefined,
        filters: page_query || undefined,
        user_id: user || undefined,
        title: title || '',
        ...overrides,
      },
    });

    if (page_term) {
      pushMetriloEvent('search', page_term, window.location.href);
      return;
    }

    switch (pageType) {
      case 'Product-Listing':
        pushMetriloEvent('viewCategory', metriloConfig?.categoryId);
        break;
      case 'Product':
        // Don't do anything here, it is measured in the viewItem function
        break;
      default:
        pushMetriloEvent('viewPage', window.location.href, { name: title || '' });
        break;
    }
  }

  function selectPromotion(title, creative, order) {
    // TagManager.dataLayer({
    //   dataLayer: {
    //     ecommerce: null,
    //   },
    // });

    TagManager.dataLayer({
      dataLayer: {
        event: 'select_promotion',
        ecommerce: {
          currency: 'EUR',
          items: [
            {
              promotion_id: title,
              promotion_name: title,
              creative_name: creative,
              creative_slot: `slot${order}`,
            },
          ],
        },
      },
    });
  }

  function viewPromotion(promotions) {
    const promotionsArray = [];

    promotions?.map(({ data, creative }) => {
      if (creative === 'content_grid') {
        return;
      } else {
        promotionsArray.push({
          id: data?.title,
          name: data?.title,
          creative: findCreative(creative),
          // position: `slot${index}`,
        });
      }
    });

    for (let i = 0; i < promotions.length; i++) {
      if (promotions[i].creative === 'content_grid') {
        for (let x = 0; x < promotions[i]?.data.length; x++) {
          const accessor = promotions[i]?.data[x].data;

          promotionsArray.push({
            id: accessor?.title,
            name: accessor?.title,
            creative: findCreative(promotions[i].creative),
            // position: `slot${x}`,
          });
        }
      }
    }

    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null,
      },
    });

    TagManager.dataLayer({
      dataLayer: {
        event: 'view_promotion',
        ecommerce: {
          currency: 'EUR',
          items: promotions?.map(({ data, creative }, index) => {
            return generateItem(data, {
              promotion_id: data.listId,
              promotion_name: data.subtitle,
              index: index + 1,
              item_category: data.title,
              creative_name: creative,
            });
          }),
        },
      },
    });
  }

  function selectItem(title, id, price, path, category, stock_level, list_price, list_name, sku) {
    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null,
      },
    });
    TagManager.dataLayer({
      dataLayer: {
        event: 'select_item',
        ecommerce: {
          currency: 'EUR',
          items: [
            {
              item_id: id,
              id: sku,
              item_name: title,
              title: title,
              item_price: price,
              price: price,
              item_category: category,
              item_category2: '',
              item_category3: '',
              item_category4: '',
              item_category5: '',
              item_stock_quantity: stock_level ? parseInt(stock_level?.toString().substring(0, 4)) : '',
              item_brand: 'AMM',
              index: 1,
              quantity: 1,
              item_variant: '',
              coupon: '',
              item_list_name: list_name ? list_name : category,
              item_list_id: list_name ? list_name : category,
              discount: list_price ? +(list_price - price).toFixed(2) : '',

              currency: 'EUR',
              affiliation: 'Online eShop',
              location_id: '',

              sku_availability: '',
              google_business_vertical: 'retail',
            },
          ],
        },
      },
    });
  }
  function viewItem(props) {
    window.omnisend = window.omnisend || [];

    window.omnisend.push([
      'track',
      '$productViewed',
      {
        $productID: props?.master_sku?.split('-')[0],
        $variantID: props?.default_variation?.sku,
        $currency: 'Euro',
        $tags: [],
        $price: props?.default_variation?.number * 100,
        // $oldPrice: “old price in cents”,
        $title: props?.title,
        $description: props?.descr,
        $imageUrl: props?.productMedia[0]?.url,
        $productUrl: `${url}${props?.path}`,
        $vendor: 'Anna Maria Mazaraki',
      },
    ]);

    TagManager.dataLayer({
      dataLayer: {
        event: 'view_item',
        ecommerce: {
          currency: 'EUR',
          value: props?.default_variation?.number,
          items: [
            {
              item_id: props?.master_sku?.split('-')[0],
              id: props?.default_variation?.sku,
              item_name: props?.title,
              title: props?.title,
              item_price: props?.default_variation?.number,
              price: props?.default_variation?.number,
              item_category: props?.category?.name,
              item_category2: '',
              item_category3: '',
              item_category4: '',
              item_category5: '',
              item_stock_quantity: parseInt(props?.default_variation?.stock_level?.toString().substring(0, 4)),
              item_brand: 'AMM',

              quantity: 1,
              item_variant: '',
              coupon: '',

              discount: props?.default_variation?.list_number
                ? +(props?.default_variation?.list_number - props?.default_variation?.number).toFixed(2)
                : '',

              currency: 'EUR',
              affiliation: 'Online eShop',
              location_id: '',

              sku_availability: '',
              google_business_vertical: 'retail',
            },
          ],
        },
      },
    });

    pushMetriloEvent('viewProduct', props?.default_variation?.id);
  }

  function viewItemList(allItems, total_results, search, customBracelet, item_list_name, startIndex) {
    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null,
      },
    });

    TagManager.dataLayer({
      dataLayer: {
        event: 'view_item_list',
        search_results: total_results,
        ecommerce: {
          currency: 'EUR',

          items: allItems?.map((item, index) => {
            return {
              item_id: item?.mastersku?.split('-')[0],
              id: item?.default_variation?.sku,
              item_name: item?.title,
              title: item?.title,
              item_price: item?.price,
              price: item?.price,
              item_category: item?.category,
              item_category2: '',
              item_category3: '',
              item_category4: '',
              item_category5: '',
              item_stock_quantity: parseInt(item?.default_variation.stock_level?.toString().substring(0, 4)),
              item_brand: 'AMM',
              index: startIndex + index + 1,
              quantity: 1,
              item_variant: '',
              coupon: '',
              item_list_name: search ? 'Search' : customBracelet ? item?.item_list_name : item_list_name,
              item_list_id: search ? 'Search' : customBracelet ? item?.item_list_name : item_list_name,
              discount: item?.list_price ? +(item?.list_price - item?.price).toFixed(2) : '',

              currency: 'EUR',
              affiliation: 'Online eShop',
              location_id: '',

              sku_availability: '',
              google_business_vertical: 'retail',
            };
          }),
        },
      },
    });
  }

  function viewItemListRelativeItems(items, isInCart, isInProductPage, tabList, homepage, label) {
    if (!items) return;

    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null,
      },
    });

    TagManager.dataLayer({
      dataLayer: {
        event: 'view_item_list',
        search_results: items.length,
        ecommerce: {
          currency: 'EUR',
          items: items?.map((item, index) => {
            return {
              item_id: item?.mastersku,
              id: item?.default_variation?.sku,
              title: item?.title,
              item_name: item?.title,
              item_price: item?.price,
              price: item?.price,
              discount: item?.list_price ? +(item?.list_price - item?.price).toFixed(2) : '',
              coupon: '',
              currency: 'EUR',
              item_brand: 'AMM',
              item_category: item?.category,
              item_category2: '',
              item_category3: '',
              item_category4: '',
              item_category5: '',
              item_variant: '',
              affiliation: 'Online Shop',
              item_list_name: isInCart
                ? 'Μπορεί να σας ενδιαφέρουν'
                : isInProductPage
                ? 'Σας προτείνουμε επίσης'
                : item?.list_name,
              item_list_id: isInCart
                ? 'Μπορεί να σας ενδιαφέρουν'
                : isInProductPage
                ? 'Σας προτείνουμε επίσης'
                : tabList
                ? label
                : item?.list_name,
              index: homepage ? item?.index : index + 1,
              location_id: '',
              quantity: 1,
              item_stock_quantity: parseInt(item?.default_variation?.stock_level?.toString().substring(0, 4)),
              sku_availability: '',
              google_business_vertical: 'retail',
            };
          }),
        },
      },
    });
  }

  function addToCart(items, totalPrice, data, userData) {
    if (!items) return;

    omnisendTracking({
      items: items,
      totalPrice: totalPrice,
      data: data,
      userData: userData,
      actionType: 'added product to cart',
    });

    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null,
      },
    });
    TagManager.dataLayer({
      dataLayer: {
        event: 'add_to_cart',
        ecommerce: {
          currency: 'EUR',
          value: items[0]?.unit_price,
          items: [
            {
              item_id: items[0]?.sku?.split('-')[0],
              id: items[0]?.sku,
              item_name: items[0]?.product_title,
              title: items[0]?.product_title,
              price: items[0]?.unit_price,
              item_price: items[0]?.unit_price,
              discount: items[0]?.list_price ? +(items[0]?.list_price - items[0]?.unit_price).toFixed(2) : '',
              coupon: '',
              currency: items[0]?.currency,
              item_brand: 'AMM',
              item_category: items[0]?.category,
              item_category2: '',
              item_category3: '',
              item_category4: '',
              item_category5: '',
              item_variant: items[0]?.size === 'none' ? '' : items[0]?.size,
              item_stock_quantity: parseInt(items[0]?.stock_level?.toString().substring(0, 4)),
              location_id: '',
              affiliation: 'Online eshop',
              quantity: 1,
              google_business_vertical: 'retail',
            },
          ],
        },
      },
    });
    if (items && items.length > 0) pushMetriloEvent('addToCart', items[0].product_id, 1);
  }

  function checkoutBegin(items, totalPrice, coupons, data, userData) {
    if (!items) return;
    omnisendTracking({
      items: items,
      totalPrice: totalPrice,
      data: data,
      userData: userData,
      actionType: 'placed order',
    });

    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null,
      },
    });

    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null,
      },
    });

    TagManager.dataLayer({
      dataLayer: {
        event: 'begin_checkout',
        num_items: items?.reduce?.((previousValue, currentValue) => {
          return previousValue + Number(currentValue?.quantity);
        }, 0),

        ecommerce: {
          currency: 'EUR',
          value: totalPrice,
          coupon: coupons?.map?.((i) => i.code).join('>'),
          items: items?.map((item) => {
            return {
              item_id: item?.sku?.split('-')[0],
              id: item?.sku,
              item_name: item?.product_title,
              title: item?.product_title,
              price: item?.unit_price,
              item_price: item?.unit_price,
              discount: item?.list_price ? +(item?.list_price - item?.unit_price).toFixed(2) : '',
              coupon: '',
              currency: item?.currency,
              item_brand: 'AMM',
              item_category: item?.category,
              item_category2: '',
              item_category3: '',
              item_category4: '',
              item_category5: '',
              item_variant: item?.size,
              item_stock_quantity: parseInt(item?.stock_level?.toString().substring(0, 4)),
              location_id: '',
              affiliation: 'Online eshop',
              quantity: +item?.quantity,
              google_business_vertical: 'retail',
            };
          }),
        },
      },
    });

    pushMetriloEvent('checkout');
  }

  function paymentMethodOption(items, totalPrice, option, payment_method, checkoutData) {
    if (!items) return;

    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null,
      },
    });

    TagManager.dataLayer({
      dataLayer: {
        num_items: items?.reduce?.((previousValue, currentValue) => {
          return previousValue + Number(currentValue?.quantity);
        }, 0),
        event: 'add_payment_info',
        value: totalPrice,
        ecommerce: {
          coupon: checkoutData?.coupons?.map?.((i) => i.code).join('>'),
          currency: 'EUR',
          payment_type: payment_method,

          items: items?.map((item) => {
            return {
              item_id: item?.sku?.split('-')[0],
              id: item?.sku,
              item_name: item?.product_title,
              title: item?.product_title,
              price: item?.unit_price,
              item_price: item?.unit_price,
              discount: item?.list_price ? +(item?.list_price - item?.unit_price).toFixed(2) : '',
              currency: item?.currency,
              item_brand: 'AMM',
              item_category: item?.category,
              item_category2: '',
              item_category3: '',
              item_category4: '',
              item_category5: '',
              item_variant: item?.size,
              item_stock_quantity: parseInt(item?.stock_level?.toString().substring(0, 4)),
              location_id: '',
              affiliation: 'Online eshop',
              quantity: +item?.quantity,
              google_business_vertical: 'retail',
            };
          }),
        },
      },
    });
  }

  function shippingMethodOption(checkoutData, shippingInfo) {
    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null,
      },
    });

    TagManager.dataLayer({
      dataLayer: {
        event: 'add_shipping_info',
        num_items: checkoutData?.order_items?.reduce?.((previousValue, currentValue) => {
          return previousValue + Number(currentValue?.quantity);
        }, 0),
        ecommerce: {
          value: checkoutData?.total_price,
          currency: 'EUR',
          shipping_tier:
            checkoutData?.shipping_method?.type === 'pickup'
              ? `${checkoutData?.shipping_method?.type} - ${checkoutData?.shipping_method?.label}`
              : shippingInfo,
          coupon: checkoutData?.coupons?.map?.((i) => i.code).join('>'),
          items: checkoutData?.order_items?.map((item) => {
            return {
              item_id: item?.sku?.split('-')[0],
              id: item?.sku,
              item_name: item?.product_title,
              title: item?.product_title,
              price: item?.unit_price,
              item_price: item?.unit_price,
              discount: item?.list_price ? +(item?.list_price - item?.unit_price).toFixed(2) : '',
              currency: item?.currency,
              item_brand: 'AMM',
              item_category: item?.category,
              item_category2: '',
              item_category3: '',
              item_category4: '',
              item_category5: '',
              item_variant: item?.size,
              item_stock_quantity: parseInt(item?.stock_level?.toString().substring(0, 4)),
              location_id: '',
              affiliation: 'Online eshop',
              quantity: +item?.quantity,
              google_business_vertical: 'retail',
            };
          }),
        },
      },
    });
  }

  function successfullOrder(items, totalPrice, orderId, makeAwish, data, userId, adjustments, userData) {
    if (!items) return;
    let donationItemObj = items.filter((item) => item.type === 'donation');
    let donationItemPrice = donationItemObj?.reduce?.((previousValue, currentValue) => {
      return previousValue + Number(currentValue?.price || 0);
    }, 0);
    let totalCouponDiscount = adjustments.filter((item) => (item.label = 'Εκπτωτικό κουπόνι'));
    let totalCoupons = totalCouponDiscount?.reduce?.((previousValue, currentValue) => {
      return previousValue + Number(currentValue?.amount || 0);
    }, 0);
    let totalListPrice = items?.reduce?.((previousValue, currentValue) => {
      return previousValue + Number(currentValue?.list_price || 0) * Number(currentValue?.quantity);
    }, 0);
    let totalItemPrice = items?.reduce?.((previousValue, currentValue) => {
      return previousValue + Number(currentValue?.price || 0);
    }, 0);
    omnisendTracking({
      items: items,
      totalPrice: totalPrice,
      data: data,
      userData: userData,
      actionType: 'placed order final',
    });
    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null,
      },
    });

    TagManager.dataLayer({
      dataLayer: {
        num_items: data?.order_items?.reduce?.((previousValue, currentValue) => {
          return previousValue + Number(currentValue?.quantity);
        }, 0),
        event: 'purchase',
        email: data?.customer_email,
        phone: data?.shipping_profile?.telephone,
        user_id: userId,
        new_customer: '',
        total_discount: totalListPrice ? +(totalListPrice - totalItemPrice - totalCoupons).toFixed(2) : 0,
        ecommerce: {
          transaction_id: data?.order_id,
          affiliation: 'Online Shop',
          value: donationItemObj ? totalPrice - donationItemPrice : totalPrice,
          tax: data?.vat || 0,
          currency: 'EUR',
          shipping: data?.adjustments[0].amount,
          purchase_payment_type: data?.payment_method,
          purchase_shipping_tier: data?.shipping_method.label,
          coupon: data?.coupons?.map?.((i) => i.code).join('>'),
          items: items.map((item) => {
            return {
              item_id: item?.sku?.split('-')[0],
              id: item?.sku,
              item_name: item?.product_title,
              title: item?.product_title,
              price: item?.unit_price,
              item_price: item?.unit_price,
              discount: item?.list_price ? +(item?.list_price - item?.unit_price).toFixed(2) : '',
              currency: 'EUR',
              item_brand: 'AMM',
              item_category: item?.category,
              item_category2: '',
              item_category3: '',
              item_category4: '',
              item_category5: '',
              item_variant: item?.size,

              location_id: '',
              affiliation: 'Online eshop',
              quantity: +item?.quantity,
              google_business_vertical: 'retail',
            };
          }),
        },
      },
    });
  }

  function removeFromCart(item, items, totalPrice, quantity) {
    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null,
      },
    });

    TagManager.dataLayer({
      dataLayer: {
        value: item?.unit_price,
        event: 'remove_from_cart',
        ecommerce: {
          currency: 'EUR',
          item: [
            {
              item_id: item?.sku?.split('-')[0],
              id: item?.sku,
              item_name: item?.product_title,
              title: item?.product_title,
              price: item?.price,
              item_price: item?.price,
              discount: item?.list_price ? +(item?.list_price - item?.unit_price).toFixed(2) : '',
              coupon: '',
              currency: item?.currency,
              item_brand: 'AMM',
              item_category: item?.category,
              item_category2: '',
              item_category3: '',
              item_category4: '',
              item_category5: '',
              item_variant: item?.size,
              item_stock_quantity: parseInt(item?.stock_level?.toString().substring(0, 4)),
              location_id: '',
              affiliation: 'Online eshop',
              quantity: +quantity,
              google_business_vertical: 'retail',
            },
          ],
        },
      },
    });
    pushMetriloEvent('removeFromCart', item?.product_id, 1);
  }
  function addToWishlist(item, isInProductPage) {
    if (!item) return;

    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null,
      },
    });

    TagManager.dataLayer({
      dataLayer: {
        event: 'add_to_wishlist',
        ecommerce: {
          currency: 'EUR',
          value: isInProductPage ? item?.default_variation?.number : item?.unit_price,
          item: [
            {
              item_id: !isInProductPage ? item?.sku?.split('-')[0] : item?.master_sku?.split('-')[0],
              id: !isInProductPage ? item?.sku : item?.default_variation.sku,
              item_name: !isInProductPage ? item?.product_title : item?.title,
              title: !isInProductPage ? item?.product_title : item?.title,
              price: !isInProductPage ? item?.unit_price : item?.default_variation?.number,
              item_price: !isInProductPage ? item?.unit_price : item?.default_variation?.number,
              discount: !isInProductPage
                ? item?.list_price
                  ? +(item?.list_price - item?.unit_price).toFixed(2)
                  : ''
                : item?.default_variation?.list_number
                ? +(item?.default_variation?.list_number - item?.default_variation?.number).toFixed(2)
                : '',
              coupon: '',
              currency: !isInProductPage ? item?.currency : item?.default_variation?.currency,
              item_brand: 'AMM',
              item_category: !isInProductPage ? item?.category : item?.category?.name,
              item_category2: '',
              item_category3: '',
              item_category4: '',
              item_category5: '',
              item_variant: !isInProductPage ? item?.size : '',
              item_stock_quantity: isInProductPage
                ? parseInt(item?.default_variation?.stock_level?.toString().substring(0, 4))
                : parseInt(item?.stock_level?.toString().substring(0, 4)),
              location_id: '',
              affiliation: 'Online eshop',
              quantity: 1,
              google_business_vertical: 'retail',
            },
          ],
        },
      },
    });
  }

  function viewCart(items, totalPrice, isDeleted) {
    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null,
      },
    });
    TagManager.dataLayer({
      dataLayer: {
        event: 'view_cart',
        num_items: !isDeleted
          ? items?.reduce?.((previousValue, currentValue) => {
              return previousValue + Number(currentValue?.quantity);
            }, 0)
          : 0,
        ecommerce: {
          currency: 'EUR',
          value: totalPrice,
          items: items?.map((item) => {
            return {
              id: item?.sku,
              item_id: item?.sku?.split('-')[0],
              item_name: item?.product_title,
              item_price: item?.unit_price,
              price: item?.unit_price,
              item_category: item?.category,
              item_category2: '',
              item_category3: '',
              item_category4: '',
              item_category5: '',
              item_brand: 'AMM',
              discount: item?.list_price ? +(item?.list_price - item?.unit_price).toFixed(2) : 0,
              item_variant: item?.size,
              title: item?.product_title,
              quantity: +item?.quantity,
              currency: 'EUR',
              coupon: '',
              affiliation: 'Online eShop',
              location_id: '',
              item_stock_quantity: parseInt(item?.stock_level?.toString().substring(0, 4)),
              sku_availability: '',
              google_business_vertical: 'retail',
            };
          }),
        },
      },
    });
  }

  function login(id) {
    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null,
      },
    });

    TagManager.dataLayer({
      dataLayer: {
        event: 'login',
        user_id: id,
        method: 'Form', // Values: Form | Google | <any oAuth provider>
      },
    });
  }
  function viewLogin() {
    TagManager.dataLayer({
      dataLayer: {
        event: 'view_login-signup',
      },
    });
  }

  function omnisendTracking({ items, totalPrice, data, userData, actionType }) {
    // console.log('items', items);
    // console.table('data', data);
    // console.table('userData', userData);

    window.omnisend = window.omnisend || [];
    const cartItems = [];
    let addedItem = {};
    if (actionType === 'added product to cart') {
      addedItem = {
        productID: items[0]?.variation_id,
        productDescription: items[0]?.product_title,
        productImageURL: items[0]?.variation_id,
        productPrice: items[0]?.price,
        productTitle: items[0]?.product_title,
        productURL: items[0]?.variation_id,
      };
    }
    data.order_items.map((item) => {
      cartItems.push({
        productID: item?.variation_id,
        productDescription: item?.product_title,
        productImageURL: item?.variation_id,
        productPrice: item?.price,
        productTitle: item?.product_title,
        productURL: item?.variation_id,
      });
    });

    const events = [
      {
        actionType: 'started checkout',
        action: 'started checkout',
        url: 'https://annamariamazaraki/el/checkout',
        type: 'push',
        pushEvent: false,
      },
      {
        actionType: 'added product to cart',
        action: 'added product to cart',
        url: 'https://annamariamazaraki/el/cart',
        type: 'push',
        pushEvent: false,
      },
      {
        actionType: 'placed order',
        action: 'placed order',
        url: 'https://api.omnisend.com/v5/events',
        type: 'post',
        pushEvent: true,
      },
      {
        actionType: 'placed order final',
        action: 'placed order',
        url: 'https://api.omnisend.com/v5/events',
        type: 'post',
        pushEvent: false,
      },
    ];

    const event = events.filter((e) => e.actionType === actionType)[0];
    console.log(event);
    if (event.type === 'push') {
      console.log('started checkout action called');

      window.omnisend.push([
        'track',
        event.action,
        {
          origin: 'api',
          properties: {
            abandonedCheckoutURL: event.url,
            cartID: data?.order_id,
            value: data?.total_price,
            currency: 'EUR',
            addedItem: addedItem ?? null,
            lineItems: cartItems ?? null,
          },
          contact: {
            email: userData?.email ?? 'guest',
          },
        },
      ]);
    }
    if (event.type === 'post') {
      const headers = { 'X-API-KEY': '5bdff8d38653ed3b5e4b9d9a-kmWNH5JQt7c4vxLwKMIRlzr17siLYTHA0D3sbAPHCk4xMIpHTc' };
      console.log('placed order action called');
      axios({
        url: 'https://api.omnisend.com/v5/events',
        method: 'POST',
        headers: headers,
        data: {
          eventName: event.action,
          origin: 'api',
          eventVersion: 'v2',
          contact: {
            email: userData?.email ?? 'guest',
          },
          properties: {
            orderID: data?.order_id,
            totalPrice: totalPrice,
            createdAt: new Date(),
            currency: 'EUR',
            paymentStatus: 'pending',
            fulfillmentStatus: 'unfulfilled',
            lineItems: cartItems,
          },
        },
      })
        .then((res) => {
          if (event.pushEvent) {
            omnisendTracking({
              items: items,
              totalPrice: totalPrice,
              data: data,
              userData: userData,
              actionType: 'start checkout',
            });
          }
        })
        .catch((e) => {
          console.log('error: ', e);
        });
    }
  }

  return {
    initialize,
    pageView,
    checkoutBegin,
    viewPromotion,
    selectPromotion,
    viewItemList,
    selectItem,
    viewItem,
    addToCart,
    removeFromCart,
    addToWishlist,
    viewCart,
    paymentMethodOption,
    successfullOrder,
    viewItemListRelativeItems,
    login,
    shippingMethodOption,
    viewLogin,
    articleView,
    identifyCustomer,
    customerDetails,
  };
};

export default Tracking();
