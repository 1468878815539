import { Box, Button, Text, Link, useToast } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import FormInput from 'components/common/Forms/FormInput';
import useTranslation from 'next-translate/useTranslation';
import { login, loginWithFB, setAuthInfo } from 'services/auth';
import FacebookLogin from 'components/common/FacebookLogin';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import useUser from 'hooks/useUser';
import useCart from 'hooks/useCart';
import { getErrorMessage } from 'utils/helpers';
import { getUser } from 'services/user';
import Tracking from 'utils/tracking';

export default function LoginForm({ onWishlist }) {
  const router = useRouter();
  const toast = useToast();
  const { t } = useTranslation('user');
  const { mutate } = useUser();
  const { mutate: mutateCart } = useCart();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [loadingFB, setFBLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const { data: authInfo } = await login(data.email, data.password);

      setAuthInfo(authInfo);
      const { data: personalInfo } = await getUser(router.locale);
      await mutate();
      await mutateCart();

      if (router.query.destination) {
        router.push(router.query.destination);
        return;
      }

      Tracking.login(personalInfo?.id);
      onWishlist ? router.push('/user/wishlist') : router.push('/user');
    } catch (err) {
      console.log(err);
      toast({
        description: getErrorMessage(err),
        position: 'top',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const onLoginWithFBSuccess = async (res) => {
    setFBLoading(true);
    try {
      const { data: authInfo } = await loginWithFB(
        res?.authResponse?.accessToken,
        res?.authResponse?.userID,
        res?.authResponse?.expiresIn
      );

      setAuthInfo(authInfo);
      await mutate();
      await mutateCart();

      if (router.query.destination) {
        router.push(router.query.destination);
        return;
      }

      onWishlist ? router.push('/user/wishlist') : router.push('/user');
    } catch (err) {
      console.log(err);
      toast({
        description: getErrorMessage(err),
        position: 'top',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setFBLoading(false);
    }
  };

  const onLoginWithFBError = (error) => {
    console.log(error);
    toast({
      description: t('errorFBLogin'),
      position: 'top',
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
  };

  return (
    <Box
      order="1"
      as={'form'}
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      w={{ base: onWishlist ? '0%' : '100%', lg: onWishlist ? '100%' : '50%' }}
      paddingLeft={{ base: '0', lg: onWishlist ? '0px' : '65px' }}
    >
      <Text as="h2" fontSize="36px" lineHeight="45px" textAlign="center" fontWeight="bold" p="40px 0">
        {t('alreadyMember')}
      </Text>
      <Text fontSize="14px" lineHeight="18px" color="borderColorForm" paddingBottom="15px">
        {t('common:requiredField')}
      </Text>
      <Box marginBottom="20px">
        <FormInput
          inputProps={{
            type: 'email',
            ...register('email', {
              required: 'required field',
              pattern: {
                value:
                  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                message: 'please provide a valid email', // JS only: <p>error message</p> TS only support string
              },
            }),
          }}
          id="email"
          isRequired
          marginRight="20px"
          label={t('common:email')}
          error={errors?.email?.message}
        />
      </Box>
      <FormInput
        controlProps={{ mb: '20px' }}
        inputProps={{
          type: 'password',
          ...register('password', { required: 'required field' }),
        }}
        id="password"
        isRequired
        label={t('password')}
        error={errors?.password?.message}
      />
      <NextLink href={'/forgot-password'} passHref prefetch={false}>
        <Link>
          <Text marginBottom="35px" fontSize="14px" fontWeight="normal" lineHeight="18px" textDecoration="underline">
            {t('forgotPassword')}
          </Text>
        </Link>
      </NextLink>
      <Button w={'250px'} display={'flex'} m="auto" type={'submit'} isLoading={loading}>
        {t('login')}
      </Button>

      <Text fontWeight={'bold'} my={'20px'} textAlign={'center'}>
        {t('common:or')}
      </Text>

      <FacebookLogin
        loading={loadingFB}
        appId="186522703260527"
        onSuccess={onLoginWithFBSuccess}
        onError={onLoginWithFBError}
      />
    </Box>
  );
}
