import { Button } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import useTranslation from 'next-translate/useTranslation';

export default function FacebookLogin({ appId, onSuccess, onError, loading }) {
  const { t } = useTranslation('common');
  useEffect(() => {
    if (!document.getElementById('jsFacebookSDK')) {
      const script = document.createElement('script');
      script.src = 'https://connect.facebook.net/en_US/sdk.js';
      script.async = true;
      script.defer = true;
      script.crossorigin = 'anonymous';
      script.id = 'jsFacebookSDK';
      script.onload = onLoad;

      document.body.appendChild(script);
    }
  }, []);

  function onLoad() {
    window.fbAsyncInit = function () {
      FB.init({
        appId: appId,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v8.0',
      });
    };
  }

  function handleSuccess(res) {
    onSuccess(res);
  }

  function handleError(res) {
    onError(res);
  }

  function handleLogin() {
    FB.getLoginStatus(function (response) {
      if (response.status === 'connected') {
        // The user is logged in and has authenticated your
        // app, and response.authResponse supplies
        // the user's ID, a valid access token, a signed
        // request, and the time the access token
        // and signed request each expire.
        handleSuccess(response);
      } else if (response.status === 'not_authorized') {
        // The user hasn't authorized your application.  They
        // must click the Login button, or you must call FB.login
        // in response to a user gesture, to launch a login dialog.
        FB.login(handleSuccess, {scope: 'public_profile, email'});
      } else {
        // The user isn't logged in to Facebook. You can launch a
        // login dialog with a user gesture, but the user may have
        // to log in to Facebook before authorizing your application.
        FB.login(handleSuccess, {scope: 'public_profile, email'});
      }
    });
  }

  return (
    <Button
      isLoading={loading}
      isDisabled={loading}
      w={'250px'}
      display={'flex'}
      m="auto"
      bg={'facebookBlue'}
      onClick={handleLogin}
      _hover={{
        opacity: 0.7,
      }}
    >
      {t('loginViaFacebook')}
    </Button>
  );
}
