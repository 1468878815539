import { useRouter } from 'next/router';
import { getRealLocale } from 'utils/helpers';
import { getWishlist as apiGetWishlist } from 'services/wishlist';
import useSWR from 'swr';

export default function useWishlist() {
  const router = useRouter();
  const locale = getRealLocale(router.locale);
  const url = `/${locale}/api/v1/wishlist`;

  const fetcher = () => apiGetWishlist(locale).then((res) => res.data);

  const {
    data: wishListData,
    error,
    mutate,
  } = useSWR([url, locale], fetcher, {
    revalidateOnMount: true,
  });

  return {
    url,
    wishListData: wishListData ? wishListData : [],
    error,
    mutate,
  };
}
