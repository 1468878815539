import { Image, Modal, ModalOverlay, ModalContent, ModalBody, Box, Select, Link } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';

const NewsletterModal = ({ isOpen, onClose, text }) => {
  const { t } = useTranslation('common');

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          maxW={{ base: '75%', lg: '850px' }}
          h={{ base: 'auto', lg: '440px' }}
          backgroundColor={'brand.900'}
          borderRadius="0"
        >
          <ModalBody display={{ base: 'block', lg: 'flex' }} padding="25px">
            <Image
              src={'/assets/newsletterImg.jpg'}
              border="5px solid white"
              h={{ base: '220px', lg: 'auto' }}
              w={{ base: '100%', lg: 'auto' }}
              objectFit="cover"
              marginBottom={{ base: '25px', lg: '0' }}
              alt="newslleter image"
            ></Image>
            <Box
              display={{ base: 'block', lg: 'flex' }}
              flexDir="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              padding="0 35px"
            >
              <Box as="h2" fontSize="36px" lineHeight="40px" color="white" paddingBottom="25px">
                {t('selectCountry')}
              </Box>
              <Box w="100%" textAlign="left" color="white" paddingBottom="15px">
                <Box padding="0 0 4px 3px" fontSize="14px" lineHeight="18px">
                  {t('country')}
                </Box>
                <Select placeholder="Ελλάδα (EUR€)" borderRadius="0" className="select-country">
                  <option value="option1">Option 1</option>
                  <option value="option2">Option 2</option>
                </Select>
              </Box>
              <Box w="100%" textAlign="left" color="white">
                <Box padding="0 0 4px 3px" fontSize="14px" lineHeight="18px">
                  {t('language')}
                </Box>
                <Select placeholder={t('greek')} borderRadius="0" className="select-language">
                  <option value="option1">{t('france')}</option>
                  <option value="option2">{t('english')}</option>
                </Select>
              </Box>
              <Link
                display="block"
                fontSize="16px"
                lineHeight="20px"
                color="white"
                textDecoration="underline"
                paddingTop="20px"
              >
                {t('common:continue')}
              </Link>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default NewsletterModal;
