import { Box, Link } from '@chakra-ui/react';
import IconMenu from './IconMenu';
import LanguageSelect from './LanguageSelect';
import NextLink from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import GlobalSearch from 'components/common/GlobalSearch';

const UserNav = ({ iconMenuShow }) => {
  const { t } = useTranslation('header');
  return (
    <>
      <Box as={'nav'} flex={1} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
        {iconMenuShow && <IconMenu theColor={'white'} />}
        <NextLink passHref prefetch={false} href={'/amm-family'}>
          <Link
            ml={{ sm: '6px', md: '6px', lg: '13px', xl: '26px' }}
            mr={{ sm: '6px', md: '6px', lg: '13px', xl: '26px' }}
            color={'white'}
            fontWeight={'bold'}
          >
            AMM Family
          </Link>
        </NextLink>

        <LanguageSelect />
        <GlobalSearch />
      </Box>
    </>
  );
};

export default UserNav;
