import { Box, Image, Text, Link } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import NextLink from 'next/link';

export default function CartItem({ item, quantity, handleRemoveItem, path }) {
  const { t } = useTranslation('common');

  return (
    <>
      <Box display="flex" mb="15px">
        <Box marginRight="20px" w={'100px'} h={'100px'}>
          <Image src={item.image} w={'100px'} h={'100px'} alt={item.product_title} />

          <Text
            color={'text.secondary'}
            fontSize={'12px'}
            textDecoration={'underline'}
            pt={'10px'}
            as={'div'}
            _hover={{
              cursor: 'pointer',
            }}
            onClick={() => handleRemoveItem(item.order_item_id)}
          >
            {t('remove')}
          </Text>
        </Box>
        <Box w={'calc(100% - 120px)'}>
          <Text fontSize={{ base: '14px', lg: '18px' }} fontWeight={{ base: 'bold', lg: 'normal' }} lineHeight={1.25}>
            <NextLink href={path || '#'} passHref prefetch={false}>
              <Link>{item.product_title}</Link>
            </NextLink>
          </Text>
          <Text fontSize={'14px'} color="lightGrey" py="5px">
            {t('sku')}: {item.sku}
          </Text>
          <Text fontSize={'14px'} paddingBottom="5px" display={'flex'} justifyContent={'space-between'}>
            {item.size === 'none' || !item.size ? (
              ''
            ) : (
              <span>
                {t('size')}: {item.size}
              </span>
            )}
            <span>
              {t('quantity')}: {parseInt(quantity)}
            </span>
          </Text>

          <Box display={{ base: 'block', lg: 'flex' }} textAlign="end" justifyContent="flex-end" alignItems="center">
            <Text
              as={'span'}
              display="block"
              fontSize={'18px'}
              fontWeight={'bold'}
              paddingBottom={{ base: '8px', lg: '0' }}
            >
              {item?.unit_price_formatted}
            </Text>
          </Box>
        </Box>
      </Box>
    </>
  );
}
