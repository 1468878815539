import { useState, useEffect, memo } from 'react';
import { useRouter } from 'next/router';
import {
  Box,
  Text,
  Link,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useOutsideClick,
} from '@chakra-ui/react';
import Container from '../Container';
import { useRef } from 'react';
import Notification from './Notification';
import TabFilterMenu from '../TabFilterMenu';
import IconMenu from 'components/common/Header/IconMenu';
import NextLink from 'next/link';
import { getMenu as apiCallToFetchMenu } from 'services/menu';
import { getFooterMain } from 'services/footer';

const Menu = ({ iconMenuShow, menuAndFooterData }) => {
  const router = useRouter();
  const [menuData, setMenuData] = useState(null);
  const [footerDataMain, setFooterDataMain] = useState([]);
  const [customTopMargin, setCustomTopMargin] = useState(0);
  const [selectedMenu, setSelectedMenu] = useState(null);

  const ref = useRef();

  useOutsideClick({
    ref: ref,
    handler: () => {
      setSelectedMenu(null);
    },
  });

  useEffect(() => {
    apiCallToFetchMenu(router.locale).then((res) => {
      setMenuData(res.data);
    });
    getFooterMain(router.locale).then((res) => {
      setFooterDataMain(res.data);
    });
  }, [router.locale]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleOnClose = () => {
    // setSelectedMenu(null);
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      const headerTopMenu = document.getElementById('jsAmmHeader');
      const headerBottomOnScroll = headerTopMenu?.getBoundingClientRect().bottom;

      if (headerBottomOnScroll < 0) {
        setCustomTopMargin(0);
      } else {
        setCustomTopMargin(headerBottomOnScroll);
      }
    }
  }, [isOpen]);

  useEffect(() => {
    handleOnClose();
    if (router.asPath !== selectedMenu?.cleanUrl) {
      setSelectedMenu(null);
    }
  }, [router.asPath]);

  const thisPath = router.asPath.substr(router.asPath.lastIndexOf('/') + 1);

  return (
    <>
      <Box
        ref={ref}
        id="jsAmmMenu"
        className="overrideModal"
        as={'nav'}
        background={'white'}
        height={'65px'}
        display={{ base: 'none', lg: 'flex' }}
      >
        <Container justifyContent={'center'} display={'flex'} className="menu_container">
          {menuData?.map(
            (
              { title, submenu, entity_id, relative, external, cleanUrl, menuItemChildren, ...menuItemProps },
              index
            ) => {
              return (
                <Text ml={'15px'} mr={'15px'} color="black" as="div" key={`menuItem-${index}`} whiteSpace="nowrap">
                  {submenu ? (
                    <Link
                      pos={'relative'}
                      textDecor={selectedMenu?.title === title ? 'underline' : 'none'}
                      fontWeight={menuItemChildren?.includes(router?.asPath) ? 'bold' : 'normal'}
                      zIndex={isOpen ? 9999 : 9}
                      onClick={() => {
                        setSelectedMenu({
                          title,
                          submenu,
                          relative,
                          external,
                          cleanUrl,
                          ...menuItemProps,
                        });
                        onOpen();
                      }}
                    >
                      {title}
                    </Link>
                  ) : (
                    <NextLink href={cleanUrl} passHref prefetch={false}>
                      <Link
                        target={external ? '_blank' : '_self'}
                        onClick={() => {
                          handleOnClose();
                          setSelectedMenu({
                            title,
                            submenu,
                            relative,
                            external,
                            cleanUrl,
                            ...menuItemProps,
                          });
                        }}
                        pos={'relative'}
                        zIndex={isOpen ? 9999 : 9}
                        textDecor={selectedMenu?.title === title ? 'underline' : 'none'}
                        fontWeight={cleanUrl === router?.asPath ? 'bold' : 'normal'}
                        color={entity_id == 1872 ? 'brand.900' : 'black'}
                      >
                        {title}
                      </Link>
                    </NextLink>
                  )}
                </Text>
              );
            }
          )}
          {iconMenuShow ? <IconMenu theColor={'#D6006D'} /> : null}
        </Container>
      </Box>
      {menuAndFooterData?.map(({ position, sestions }, index) =>
        position && position === 'below_menu' ? (
          <Notification data={sestions[0]?.data?.text} key={`belowData-${index}`} />
        ) : null
      )}

      {selectedMenu?.submenu ? (
        <Modal size={'4xl'} isOpen={isOpen} onClose={handleOnClose}>
          <ModalOverlay className={'menu-modal'} top={customTopMargin} />
          <ModalContent className={'menu-modal-content'} top={customTopMargin} w={'900px'}>
            <ModalBody ref={ref} padding={'20px 65px'}>
              <Box>
                <TabFilterMenu selectedMenu={selectedMenu} onClose={handleOnClose} thisPath={thisPath} />
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      ) : (
        ''
      )}
    </>
  );
};

export default memo(Menu);
