import { extendTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';

const breakpoints = createBreakpoints({
  sm: '30em',
  md: '48em',
  lg: '69em',
  xl: '80em',
  '2xl': '96em',
});

//global styles should be placed here
const styles = {
  global: {
    'html, body': {
      fontFamily: 'gill-sans-nova, sans-serif',
      color: 'text.primary',
    },
    a: {
      color: 'brand.900',
    },
  },
};

const textStyles = {
  textStyleH: {
    fontSize: ['20px', '36px'],
    lineHeight: '45px',
    color: '#ffffff',
  },
  textStyleP: {
    fontSize: '16px',
    lineHeight: '20px',
    color: '#ffffff',
  },
  bannerP: {
    fontSize: '20px',
    lineHeight: '20px',
    color: '#ffffff',
  },
  sectionP: {
    fontSize: ['16px'],
    fontWeight: 'normal',
    lineHeight: '20px',
    color: '#D6006D',
  },
  menuLevelThreeItemsTitle: {
    fontSize: ['11px'],
    fontWeight: 'normal',
    lineHeight: '14px',
  },
  footerH4: {
    fontWeight: 'bold',
    fontSize: ['16px'],
    lineHeight: '20px',
    color: '#D6006D',
  },
  cartParagraph: {
    fontWeight: 'normal',
    fontSize: ['15px', '20px'],
    lineHeight: '25px',
  },
  cartParagraphSmall: {
    fontWeight: 'normal',
    fontSize: ['13px', '16px'],
    lineHeight: '20px',
  },
  cartPrice: {
    fontWeight: 'bold',
    fontSize: ['13px', '16px'],
    lineHeight: '20px',
  },
  cartPriceTotal: {
    fontWeight: 'bold',
    fontSize: ['15px', '20px'],
    lineHeight: '20px',
  },
  productInfoLink: {
    fontWeight: 'bold',
    fontSize: ['14px'],
    lineHeight: ' 135.35%',
    color: '#D6006D',
  },
};

const colors = {
  brand: {
    900: '#D6006D',
    800: '#E81F78',
  },
  white: '#ffffff',
  black: '#000000',
  borderColorForm: '#5e5e5e',
  borderMaps: '#E4E4E4',
  greyTable: '#F6F6F6',
  lightGrey: '#6b6b6b',
  pseudoColorGrey: '#F2F2F2',
  grey: '#D9D9D9',
  facebookBlue: '#4c69ba',
  buttons: {
    primary: '#047e09',
    secondary: '#D6006D',
  },
  text: {
    primary: '#000000',
    secondary: '#5e5e5e',
    light: '#FFFFFF',
    colored: '#D6006D',
  },
};

const fonts = {
  heading: `"Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  body: `"Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  mono: `SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace`,
};

const components = {
  Button: {
    baseStyle: {
      color: 'white',
      fontSize: '1rem',
      fontWeight: 'bold',
      borderRadius: '5px',
    },
    sizes: {
      xs: {
        height: '30px',
        padding: '0.5rem 1rem',
      },
      md: {
        height: '45px',
        padding: '1rem 2rem',
      },
    },
    variants: {
      solid: {
        bg: 'buttons.primary',
        _hover: {
          bg: 'buttons.primary',
          opacity: 0.9,
        },
        _disabled: {
          bg: 'buttons.primary !important',
        },
      },
      secondary: {
        bg: 'buttons.secondary',
        _hover: {
          bg: 'buttons.secondary',
          opacity: 0.9,
        },
      },
      outline: {
        bg: 'white',
        borderWidth: '1px',
        borderColor: 'brand.900',
        fontWeight: 'normal',
        color: 'black',
        _hover: {
          bg: 'white',

          opacity: 0.9,
        },
      },
      outlineInverted: {
        bg: 'brand.900',
        borderWidth: '1px',
        borderColor: 'white',
        color: 'white',
        _hover: {
          bg: 'white',
          borderColor: 'brand.900',
          color: 'brand.900',
        },
      },
      warning: {
        bg: 'red',
        color: 'white',
        _hover: {
          bg: 'white',
          color: 'red',
        },
      },
    },
  },
  Alert: {
    variants: {
      solid: {
        container: {
          bg: '#fff',
          border: '2px',
          borderColor: 'brand.900',
        },
        title: {
          color: 'brand.900',
        },
        description: {
          color: 'brand.900',
        },
        icon: {
          color: 'brand.900',
        },
      },
    },
  },
};

export const theme = extendTheme({ styles, colors, components, fonts, textStyles, breakpoints });
