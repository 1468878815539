import { useState, useContext, memo, useEffect } from 'react';
import AMMContext from 'context';
import {
  Box,
  Popover,
  PopoverContent,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Flex,
  Text,
  Button,
  useToast,
  PopoverTrigger,
} from '@chakra-ui/react';
import Tracking from 'utils/tracking';

import Bag from 'components/icons/Bag';
import useCart from 'hooks/useCart';
import useTranslation from 'next-translate/useTranslation';
import CartItem from './CartItem';
import { deleteItem as apiDeleteItem } from 'services/cart';
import { useRouter } from 'next/router';
import { getErrorMessage } from 'utils/helpers';
import { MINIMUM_AMOUNT_FOR_FREE_SHIPPING } from 'utils/config.js';
import { formatPrice } from 'utils/helpers';
import Discount from './Discount';

export const MiniCart = ({ boxSize }) => {
  const toast = useToast();
  const { t } = useTranslation('cart');
  const { cartData, mutate } = useCart();
  const [loadingDeleteItem, setLoadingDeleteItem] = useState(false);
  const router = useRouter();
  const { toggleCart, closeCart, cartState } = useContext(AMMContext);

  useEffect(() => {
    closeCart();
  }, [router.asPath]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    //closeCart();
  };

  const goToCart = () => {
    router.push('/cart');
  };
  const goToCheckout = () => {
    router.push('/checkout');
  };

  const handleRemoveItem = (id) => {
    setLoadingDeleteItem(true);
    apiDeleteItem(router.locale, cartData.order_id, id)
      .then(() => {
        mutate();
        const list = router.locale + router.asPath;
        Tracking.removeFromCart(cartData.order_items, cartData.total_price, list);
      })
      .catch((err) => {
        toast({
          description: getErrorMessage(err),
          position: 'top',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingDeleteItem(false);
      });
  };

  const totalPrice = Number(cartData?.total_price || 0);
  const amountForFreeString = formatPrice(MINIMUM_AMOUNT_FOR_FREE_SHIPPING - totalPrice);

  return (
    <Popover _focus={{ boxShadow: 'none' }} boxShadow={'none'} isOpen={cartState} onClose={closeCart}>
      <PopoverTrigger>
        <>
          <Box
            display={'flex'}
            mr={{ base: '7px', lg: '15px' }}
            _hover={{ cursor: 'pointer' }}
            position={'relative'}
            className={'ammMiniCart__trigger'}
            onClick={toggleCart}
          >
            <Bag boxSize={boxSize ? boxSize : null} color={'white'} />

            {cartData?.order_items && cartData?.order_items.length > 0 ? (
              <Box
                className={'ammMiniCart__indicator'}
                bg={'black'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                borderRadius={'50%'}
                position={'absolute'}
                bottom={'-8px'}
                right={'-8px'}
                padding={'3px'}
                w={'18px'}
                h={'18px'}
                lineHeight={1}
              >
                <Text fontSize={'10px'} fontWeight={'bold'} color={'white'} lineHeight={1}>
                  {cartData.order_items.length}
                </Text>
              </Box>
            ) : null}
          </Box>
          <PopoverContent
            position={'fixed'}
            top={{ base: '80px', lg: '80px' }}
            right={{ base: '0px', lg: '40px' }}
            borderRadius={'none'}
            boxShadow={'none'}
            outline={'none'}
            px={'20px'}
            width={'100%'}
            borderColor={'brand.900'}
            maxW={{ base: '320px', lg: '320px' }}
            minW={'320px !important'}
            _focus={{ boxShadow: 'none', outline: 'none' }}
          >
            <PopoverCloseButton />
            <PopoverHeader fontSize={'14px'} fontWeight={'bold'}>
              {t('myCart')}
              {cartData?.order_items && cartData?.order_items.length > 0 ? (
                <Text color={'brand.900'} as={'span'} fontWeight={'normal'}>
                  {`, ${cartData.order_items.length} ${
                    cartData.order_items.length === 1 ? t('product') : t('user:products')
                  }`}
                </Text>
              ) : null}
            </PopoverHeader>

            <PopoverBody
              h={!cartData || !cartData.order_items || cartData.order_items.length === 0 ? 'auto' : '250px'}
              overflowY={'auto'}
              mt={'20px'}
            >
              {!cartData || !cartData.order_items || cartData.order_items.length === 0 ? (
                <Text color={'text.secondary'} fontSize={'14px'} pb={'20px'}>
                  {t('emptyCart')}
                </Text>
              ) : null}

              {cartData?.order_items && cartData?.order_items.length > 0 ? (
                <Box
                  opacity={loadingDeleteItem ? 0.5 : 1}
                  pointerEvents={loadingDeleteItem ? 'none' : 'auto'}
                  transition={'all 0.2s ease-in-out'}
                >
                  {cartData?.order_items.map((i) => {
                    return (
                      <CartItem
                        key={i.order_item_id}
                        order_id={cartData.order_id}
                        handleRemoveItem={handleRemoveItem}
                        quantity={i.quantity}
                        item={i}
                        path={i.path}
                      />
                    );
                  })}
                </Box>
              ) : null}

              {cartData?.adjustments && cartData?.adjustments.length > 0 ? (
                <Box>
                  {cartData?.adjustments.map((i) => {
                    return (
                      <Discount
                        type={i.type}
                        amount_formatted={i.amount_formatted}
                        label={i.label}
                        key={`discount-${i}`}
                      />
                    );
                  })}
                </Box>
              ) : null}
            </PopoverBody>

            {cartData?.order_items && cartData?.order_items.length > 0 ? (
              <Box opacity={loadingDeleteItem ? 0.5 : 1} transition={'all 0.2s ease-in-out'}>
                <Box textAlign="center" fontSize="10px" color="lightGrey" className="free-shipping">
                  {totalPrice < 45 ? (
                    <Box as={'p'}>
                      <Box as={'span'} color={'brand.900'}>
                        {amountForFreeString}{' '}
                      </Box>
                      {t('freeShippingCartMessage')}
                    </Box>
                  ) : (
                    ''
                  )}
                </Box>
                <Box
                  display="flex"
                  justifyContent={'space-between'}
                  bg={'brand.900'}
                  px={'15px'}
                  py={'5px'}
                  mt={'20px'}
                >
                  <Text color={'white'} fontWeight={'bold'} fontSize={'18px'}>
                    {t('common:finalPrice')}
                  </Text>
                  <Text color={'white'} fontWeight={'bold'} fontSize={'18px'}>
                    {cartData.total_price_formatted}
                  </Text>
                </Box>
                <Flex alignItems={'center'} justifyContent={'space-between'} my={'20px'}>
                  <Button w="100%" variant={'outline'} size={'sm'} mr={{ base: 0, lg: '10px' }} onClick={goToCart}>
                    {t('viewCart')}
                  </Button>
                  <Button w="100%" size={'sm'} onClick={goToCheckout} ml="3px">
                    {t('payment')}
                  </Button>
                </Flex>
              </Box>
            ) : null}
          </PopoverContent>
        </>
      </PopoverTrigger>
    </Popover>
  );
};

export default memo(MiniCart);
