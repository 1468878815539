import { useEffect, useState } from 'react';
import { Tabs, TabList, TabPanels, TabPanel, Button, Box, Tab, Link } from '@chakra-ui/react';
import CarouselComp from '../CarouselComp';
import Carousel from 'react-multi-carousel';
import TabItem from '../TabItem';
import NextLink from 'next/link';
import useTranslation from 'next-translate/useTranslation';

const TabFilterMenu = ({ selectedMenu, thisPath }) => {
  const { t } = useTranslation('common');
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  useEffect(() => {
    setTabIndex(1);
  }, [selectedMenu]);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 992 },
      items: 5,
      slidesToSlide: 5, // optional, default to 1.
    },
    laptop: {
      breakpoint: { max: 992, min: 768 },
      items: 4,
      slidesToSlide: 4, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 992, min: 576 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 576, min: 320 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
  };

  return (
    <>
      <Tabs index={tabIndex} onChange={handleTabsChange}>
        <TabList>
          <Tab
            pos="relative"
            _selected={{
              _after: { top: '7px', opacity: 1 },
              _before: { bottom: '4px', opacity: 1 },
            }}
            _focus={{ boxShadow: 'none' }}
            _hover={{ color: 'brand.900' }}
            color="black"
          >
            <NextLink
              href={selectedMenu?.cleanUrl}
              passHref
              prefetch={false}
              target={selectedMenu?.external ? '_blank' : '_self'}
            >
              <Link textDecoration={'none'} _hover={{ textDecoration: 'none' }}>
                {'All collections'}
              </Link>
            </NextLink>
          </Tab>
          {selectedMenu?.submenu?.map(({ title, submenu, cleanUrl }, index) =>
            submenu && submenu.length > 0 ? (
              <TabItem label={title} key={`tabItem-${index}`} />
            ) : (
              <TabItem label={title} url={cleanUrl} link={true} thisPath={thisPath} key={`tabItem-${index}`} />
            )
          )}
        </TabList>

        <TabPanels>
          <TabPanel key={`tabPanel--1`} />
          {selectedMenu?.submenu?.map(({ submenu }, index) => (
            <TabPanel key={`tabPanel-${index}`}>
              {submenu && submenu.length > 0 ? (
                <Carousel
                  swipeable={false}
                  draggable={false}
                  showDots={false}
                  responsive={responsive}
                  ssr={true} // means to render carousel on server-side.
                  infinite={true}
                  autoPlay={false}
                  keyBoardControl={true}
                  customTransition="all .4s linear"
                  transitionDuration={500}
                  containerClass="carousel-container"
                  removeArrowOnDeviceType={['tablet', 'mobile']}
                  deviceType={'desktop'}
                  dotListClass="custom-dot-list-style"
                  itemClass="carousel-item-padding-40-px"
                >
                  {submenu?.map(({ title, menuImage, relative, cleanUrl, external }, index) => (
                    <>
                      <CarouselComp
                        key={`CarouselComp-${index}`}
                        title={title}
                        menuImage={menuImage}
                        relative={relative}
                        cleanUrl={cleanUrl}
                        external={external}
                      />
                    </>
                  ))}
                </Carousel>
              ) : null}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>

      {selectedMenu && selectedMenu?.submenu[tabIndex - 1] && selectedMenu?.submenu[tabIndex - 1].submenu ? (
        <Box textAlign={'center'}>
          <NextLink
            href={selectedMenu.submenu[tabIndex - 1].cleanUrl}
            passHref
            prefetch={false}
            target={selectedMenu.submenu[tabIndex - 1]?.external ? '_blank' : '_self'}
          >
            <Button variant={'outline'} color={'brand.900'} width={'250px'} as={'a'}>
              {t('view_all_menu')}
            </Button>
          </NextLink>
        </Box>
      ) : null}
    </>
  );
};

export default TabFilterMenu;
