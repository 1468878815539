import axios from 'axios';
import { apiURL } from 'utils/config';
import { getRealLocale } from 'utils/helpers';

export const subscribe = (locale, email) => {
  return axios({
    url: `${apiURL}/${getRealLocale(locale)}/api/v1/newsletter`,
    method: 'POST',
    data: email,
  });
};
