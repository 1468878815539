/**
 * Lazy load the FBChatComponent for better performance
 */
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

const MessengerCustomerChat = dynamic(() => import('react-messenger-customer-chat'), {
  ssr: false,
});

export default function FacebookChat() {
  const router = useRouter();

  if (process.env.NODE_ENV === 'development' || router?.pathname === '/custom-bracelet') {
    return null;
  }

  const language = router.locale === 'el' ? 'el_GR' : 'en_US';

  return (
    <MessengerCustomerChat
      pageId={process.env.NEXT_PUBLIC_FACEBOOK_PAGE_ID}
      appId={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}
      language={language}
      themeColor="#d6006d"
    />
  );
}
