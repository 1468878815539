import { Link } from '@chakra-ui/react';
import NextLink from 'next/link';

const MenuItem = ({ title, external, entity_id, url, submenu, renderSubmenu }, index) => {
  return submenu ? (
    <Link
      onClick={renderSubmenu}
      position="relative"
      className="menu-item"
      _focus={{ boxShadow: 'none' }}
      _hover={{ textDecoration: 'none' }}
      paddingRight={'5px'}
      marginBottom={'20px'}
      fontSize="14px"
      fontWeight="normal"
      lineHeight="28px"
      _before={{
        content: '""',
        position: 'absolute',
        backgroundImage: 'url(../assets/arrowMenu.svg)',
        backgroundSize: 'contain',
        display: 'block',
        w: '12px',
        h: '12px',
        right: '20px',
        top: '9px',
      }}
    >
      {title}
    </Link>
  ) : (
    <NextLink href={url || '#'} key={index}>
      <Link
        target={external ? '_blank' : '_self'}
        position="relative"
        className="menu-item"
        _focus={{ boxShadow: 'none' }}
        _hover={{ textDecoration: 'none' }}
        paddingRight={'5px'}
        marginBottom={'20px'}
        fontSize="14px"
        fontWeight="normal"
        lineHeight="28px"
        color={entity_id == 1872 ? 'brand.900' : 'black'}
        _before={{
          content: '""',
          position: 'absolute',
          backgroundImage: 'url(../assets/arrowMenu.svg)',
          backgroundSize: 'contain',
          display: 'block',
          w: '12px',
          h: '12px',
          right: '20px',
          top: '9px',
        }}
      >
        {title}
      </Link>
    </NextLink>
  );
};
export default MenuItem;
