import { FormControl, FormLabel, Input, FormErrorMessage } from '@chakra-ui/react';

export default function FormInput({
  label,
  id,
  isRequired,
  isDisabled,
  isReadOnly,
  labelProps,
  placeholder,
  inputProps,
  controlProps,
  error,
  onChange,
  upperCase,
  value,
}) {
  return (
    <FormControl className={error && 'errorClass'} isRequired={isRequired} id={id} isInvalid={error} {...controlProps}>
      {label && (
        <FormLabel textStyle="labelText" marginBottom="0" {...labelProps}>
          {label}
        </FormLabel>
      )}
      <Input
        textTransform={upperCase ? 'uppercase' : 'none'}
        onChange={onChange}
        type={'text'}
        _disabled={{ color: 'black', opacity: 0.5 }}
        borderRadius="0"
        placeholder={placeholder}
        borderColor={'text.secondary'}
        backgroundColor={'white'}
        isInvalid={error}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        isRequired={isRequired}
        value={value}
        {...inputProps}
      />
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
}
