import { Box, Flex } from '@chakra-ui/react';
import { ArrowUpIcon } from '@chakra-ui/icons';
import { useState, useEffect } from 'react';

const ScrollToTop = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 400) {
      setShowTopBtn(true);
    } else {
      setShowTopBtn(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Flex
      pos={'fixed'}
      bottom={'90px'}
      right={'30px'}
      width={'45px'}
      height={'45px'}
      borderRadius={'50%'}
      bg={'brand.900'}
      alignItems={'center'}
      justifyContent={'center'}
      cursor={'pointer'}
      onClick={() => window.scrollTo(0, 0)}
      transition={'.5s'}
      opacity={showTopBtn ? '1' : '0'}
      zIndex={'9999'}
    >
      <ArrowUpIcon w={8} h={8} color={'white'} />
    </Flex>
  );
};

export default ScrollToTop;
