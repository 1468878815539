import 'react-multi-carousel/lib/styles.css';
import { Box, Image, Link } from '@chakra-ui/react';
import NextLink from 'next/link';

const CarouselComp = ({ title, menuImage, external, cleanUrl, onClose }) => {
  return (
    <>
      <Box as={'div'}>
        <NextLink href={cleanUrl || '#'} passHref prefetch={false}>
          <Link target={external ? '_blank' : '_self'} onClick={onClose}>
            <Image src={menuImage} width={'130px'} height={'130px'} alt={title} />

            <Box as={'p'} textAlign={'center'}>
              {title}
            </Box>
          </Link>
        </NextLink>
      </Box>
    </>
  );
};

export default CarouselComp;
