import { useRouter } from 'next/router';
import { getRealLocale } from 'utils/helpers';
import { getUser as apiGetUser } from 'services/user';
import useSWR from 'swr';
import { useEffect } from 'react';
import CookieHelper from '../utils/cookie';

export default function useUser(authRoute) {
  const router = useRouter();
  const locale = getRealLocale(router.locale);
  const url = `${locale}/api/v1/user/personal_information`;
  const fetcher = () => apiGetUser(locale).then((res) => res.data);

  function hasToken() {
    if (typeof window === 'undefined') {
      return false;
    } else {
      return Boolean(CookieHelper.load('auth'));
    }
  }

  const {
    data: userData,
    error,
    mutate,
    isValidating,
  } = useSWR([url, locale], hasToken() ? fetcher : null, { revalidateOnMount: true });

  useEffect(() => {
    if ((authRoute && !hasToken()) || (!userData?.id && authRoute && !isValidating)) {
      router.push('/login');
    }
    //eslint-disable-next-line
  }, [userData?.id, authRoute, isValidating]);

  const isLoggedIn = () => {
    return Boolean(hasToken() && userData?.id && !isValidating);
  };

  return {
    url,
    isValidating,
    isLoggedIn,
    isLoyaltyUser: userData?.loyalty,
    userData: userData ? userData : { id: null },
    error,
    mutate,
  };
}
