import { Progress, Box } from '@chakra-ui/react';

export default function ProgressBar({ loading }) {
  if (!loading) return false;
  return (
    <Box className={'ProgressBar'} left={0} right={0} width={'100%'} position={'fixed'} zIndex={999}>
      <Progress isIndeterminate size={'xs'} colorScheme={'pink'} />
    </Box>
  );
}
