import axios from 'axios';
import { apiURL } from 'utils/config';
import { getRealLocale } from 'utils/helpers';

const url = apiURL;

export const getMenu = (locale) => {
  return axios({
    url: `${url}/${getRealLocale(locale)}/api/v1/menu/main`,
    method: 'GET',
  });
};
