import axios from 'axios';
import { apiURL } from 'utils/config';
import { getRealLocale } from 'utils/helpers';
import CookieHelper from 'utils/cookie';

const url = `${apiURL}`;

export const getWishlist = (locale) => {
  return axios({
    url: `${url}/${locale}/api/v1/wishlist`,
    method: 'GET',
    headers: {
      'Commerce-Cart-Token': CookieHelper.load('cartToken'),
    },
  });
};

export const addToWishlist = (locale, items) => {
  return axios({
    url: `${url}/${getRealLocale(locale)}/api/v1/wishlist`,
    method: 'POST',
    data: items,
    headers: {
      'Commerce-Cart-Token': CookieHelper.load('cartToken'),
    },
  });
};
