import { Box } from '@chakra-ui/react';
const Container = ({ children, ...props }) => {
  return (
    <Box maxW={['100%', '100%', '1440px']} px={['20px', '20px', '40px']} m={'auto'} pos="relative" {...props}>
      {children}
    </Box>
  );
};

export default Container;
