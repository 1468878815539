
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import Script from 'next/script';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { ChakraProvider } from '@chakra-ui/react';
import { useState } from 'react';
import { AMMProvider } from 'context';
import { SWRConfig } from 'swr';
import uuid from 'react-uuid';
import { theme } from 'theme';
import MainLayout from 'components/layouts/MainLayout';
import ProgressBar from 'components/common/ProgressBar';
import FacebookChat from 'components/common/FacebookChat';
import CookieHelper from 'utils/cookie';
//import Tracking from 'utils/tracking';
import ScrollToTop from 'components/common/ScrollToTop';
import 'services/interceptors';
import '../styles.css';
import '../styles/globals.css';
import EspaPopup from 'components/common/EspaPopup';

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const [routeLoading, setRouteLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [cartState, setCartState] = useState(false);
  const openCart = () => setCartState(true);
  const closeCart = () => setCartState(false);
  const toggleCart = () => setCartState(!cartState);

  useEffect(() => {
    const loadScript = () => {
      // Check if the script is already loaded
      if (!document.getElementById('reccodo-js')) {
        const script = document.createElement('script');
        script.id = 'reccodo-js';
        script.type = 'text/javascript';
        script.async = true;
        script.src = 'https://app.reccodo.com/static/js/reccodo.min.js';
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    };

    loadScript();

    // Clean up function
    return () => {
      const existingScript = document.getElementById('reccodo-js');
      if (existingScript) {
        existingScript.remove();
      }
    };
  }, [router.asPath]);

  useEffect(() => {
    if (!!cartState) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [cartState]);

  useEffect(() => {
    //Tracking.initialize();
    setTimeout(() => {
      setIsLoaded(true);
    }, 2000);
  }, []);

  useEffect(() => {
    let cartToken = CookieHelper.load('cartToken');

    if (!cartToken) {
      let cartTokenUUID = uuid();

      console.log('new cartToken', cartTokenUUID);
      CookieHelper.save('cartToken', cartTokenUUID, 500);
    }
  }, []);

  useEffect(() => {
    const handleRouteChangeStart = () => {
      setRouteLoading(true);
    };

    const handleRouteChangeEnd = () => {
      setRouteLoading(false);
    };

    const handleRouteChangeError = () => {
      setRouteLoading(false);
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeEnd);
    router.events.on('routeChangeError', handleRouteChangeError);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeEnd);
      router.events.off('routeChangeError', handleRouteChangeError);
    };
  }, []);

  return (
    <>
      {/*klarna initialization script. After this runs window.Klarna should be available to use*/}
      <Script src="https://x.klarnacdn.net/kp/lib/v1/api.js" defer />
      {/*metrilo initialization script. After this runs window.metrilo should be available to use*/}
      <Script
        async
        data-environment="production"
        src="https://js.klarna.com/web-sdk/v1/klarna.js"
        data-client-id="7a7571b5-2d41-5269-9e80-5376863673a5"
      />
      {/* e-satisfaction Script */}
      <Script
        id="esat-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function (w, d, id, c) {
              // Define e-satisfaction collection configuration
              w.esat_config = { application_id: id, collection: c || {} };

              // Update metadata
              w.Esat = w.Esat || {};
              w.Esat.updateMetadata = function (q, m) {
                w.esat_config.collection[q] = w.esat_config.collection[q] || {};
                w.esat_config.collection[q].metadata = m;
              };

              // Setup script
              var l = function () {
                var r = d.getElementsByTagName('script')[0], s = d.createElement('script');
                s.async = true;
                s.src = 'https://collection.e-satisfaction.com/dist/js/integration' + (!!w.jQuery ? '' : '.jq') + '.min.js';
                r.parentNode.insertBefore(s, r);
              };

              // Attach script or run script if document is loaded
              "complete" === d.readyState ? l() : (w.attachEvent ? w.attachEvent("onload", l) : w.addEventListener("load", l, false));
            })(window, document, 'tMj46VxATAK3wTLOaCUk3A', {});
          `,
        }}
      />
      <Script
        type="text/javascript"
        src="https://trk.mtrl.me/tracking.js?token=25e15bbcbb232663"
        strategy="afterInteractive"
      />
      {/*google analytics script*/}
      <Script
        id={'analytics-custom'}
        strategy="afterInteractive"
      >{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://analytics.annamariamazaraki.gr/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-W9R8MD6');`}</Script>
      <noscript>
        <iframe
          src="https://analytics.annamariamazaraki.gr/ns.html?id=GTM-W9R8MD6"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>

      <SWRConfig
        value={{
          dedupingInterval: 60000,
          revalidateOnFocus: false,
          shouldRetryOnError: false,
          refreshInterval: 0,
          onError: (error, key) => {
            console.log('>>> error', key, error);
          },
          onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
            if (error.status === 404 || error.status === 403 || error.status === 401) return;

            setTimeout(() => revalidate({ retryCount }), 5000);
          },
        }}
      >
        <AMMProvider value={{ openCart, closeCart, toggleCart, cartState }}>
          <ChakraProvider theme={theme}>
            <ProgressBar loading={routeLoading} />
            <MainLayout>
              <EspaPopup />
              <ScrollToTop />
              <Component {...pageProps} />
            </MainLayout>

            {isLoaded ? <FacebookChat /> : null}
          </ChakraProvider>
        </AMMProvider>
      </SWRConfig>
    </>
  );
}

const __Page_Next_Translate__ = MyApp;


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  