import { useState, useEffect } from 'react';
import { Link, Box, Button, Text } from '@chakra-ui/react';
import LanguageSelect from '../Header/LanguageSelect';
import Pin from 'components/icons/Pin';
import MenuItem from './MenuItem';
import LevelTwoMobileMenu from './LevelTwoMobileMenu';
import Container from '../Container';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';

const LevelOneMobileMenu = ({ menuData, customHeight, thisPath, setIsThird }) => {
  const { t } = useTranslation('header');
  const [secondMenu, setSecondMenu] = useState(null);
  const router = useRouter();

  return !secondMenu ? (
    <>
      <Box
        bg={'white'}
        position={'absolute'}
        w={'100vw'}
        h={'calc(100vh - 50px)'}
        top={'90px'}
        left={'0'}
        overflowY={'scroll'}
        overflowX={'hidden'}
        zIndex={'1001'}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          px="12px"
          borderBottom="1px solid #d6006d"
          bg={'white'}
        >
          <Box as={'p'} fontSize="14px" fontWeight="normal" lineHeight="18px" color={'brand.900'}>
            {t('contact')}
            <NextLink href={'tel:210 6848300'} passHref prefetch={false}>
              <Link>
                <Box as={'span'} fontSize="14px" fontWeight="bold" lineHeight="18px" paddingLeft={'8px'}>
                  {'210 6848300'}
                </Box>
              </Link>
            </NextLink>
          </Box>
          <LanguageSelect color={'brand.900'} />
        </Box>
        <Box
          position="absolute"
          backgroundColor="#000000"
          opacity=".5"
          display="block"
          w="100%"
          h="100%"
          left="0"
          top="0"
          zIndex="-1"
        ></Box>
        <Box overflowX={'hidden'} overflowY={'scroll'}>
          <Box overflowX="hidden" overflowY="scroll" className="scrollAreaContainer">
            <Box as={'div'} className={'level-generic one'} backgroundColor={'white'}>
              {menuData?.map(({ title, submenu, entity_id, external, cleanUrl, relative }, index) => {
                return (
                  <MenuItem
                    title={title}
                    external={external}
                    url={cleanUrl}
                    thisPath={thisPath}
                    entity_id={entity_id}
                    key={`menuItem-${index}`}
                    submenu={submenu ? true : false}
                    renderSubmenu={() => {
                      setSecondMenu({ submenu: submenu, title: title, relative: relative });
                    }}
                  />
                );
              })}
              <Container pos="absolute" left="50%" w="100%" id="footerMenu">
                <Box
                  paddingBottom={'100px !important'}
                  position={'relative'}
                  _before={{
                    content: '""',
                    position: 'absolute',
                    backgroundColor: 'brand.900',
                    width: '2000px',
                    height: '100%',
                    left: '-200px',
                    top: '0',
                    zIndex: '-1',
                  }}
                  padding={'18px 0'}
                  textAlign="center"
                >
                  <Box padding={'12px'} color="white">
                    <Link
                      fontSize="14px"
                      fontWeight="bold"
                      lineHeight="18px"
                      color="white"
                      onClick={() => {
                        router.push(`/${router.locale}/stores`);
                      }}
                    >
                      {t('stores')}
                    </Link>
                    {' | '}
                    <NextLink passHref prefetch={false} href={'/node/1832'}>
                      <Link fontSize="14px" fontWeight="bold" lineHeight="18px" color="white">
                        {t('giftCard')}
                      </Link>
                    </NextLink>
                    {' | '}
                    <NextLink passHref prefetch={false} href={'/blog'}>
                      <Link mr={{ sm: '6px', md: '6px', lg: '13px', xl: '26px' }} color={'white'} fontWeight={'bold'}>
                        Blog
                      </Link>
                    </NextLink>
                  </Box>
                </Box>
              </Container>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  ) : (
    <LevelTwoMobileMenu
      menuData={secondMenu}
      setIsThird={setIsThird}
      customHeight={customHeight}
      thisPath={thisPath}
      backToLevelOne={() => setSecondMenu(null)}
    />
  );
};
export default LevelOneMobileMenu;
