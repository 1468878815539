import { useRouter } from 'next/router';
import { getRealLocale } from 'utils/helpers';
import { getCart as apiGetCart } from 'services/cart';
import useSWR from 'swr';

export default function useCart() {
  const router = useRouter();
  const locale = getRealLocale(router.locale);
  const url = `/${locale}/api/v1/cart`;
  const fetcher = () => apiGetCart(locale).then((res) => res.data);

  const {
    data: cartData,
    error,
    mutate,
  } = useSWR([url, locale], fetcher, {
    revalidateOnMount: true,
  });

  return {
    url,
    cartData: cartData ? { ...cartData, order_items: cartData?.order_items?.filter((i) => i.type !== 'donation') } : {},
    error,
    mutate,
  };
}
