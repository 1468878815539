import { Link, Box, Grid, Image, Button } from '@chakra-ui/react';
import NextLink from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import LanguageSelect from '../Header/LanguageSelect';
import { useEffect } from 'react';

const LevelThreeMobileMenu = ({ menuData, resetMenu, customHeight, setIsThird }) => {
  const { t } = useTranslation('header');

  useEffect(() => {
    setIsThird(true);
  }, []);
  return (
    <>
      {/* <Box
        mt="7px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        px="12px"
        borderBottom="1px solid #d6006d"
      >
        <Box
          backgroundColor={'white'}
          as={'p'}
          fontSize="14px"
          fontWeight="normal"
          lineHeight="18px"
          color={'brand.900'}
        >
          {t('contact')}
          <NextLink href={'tel:210 6848300'} passHref prefetch={false}>
            <Link>
              <Box as={'span'} fontSize="14px" fontWeight="bold" lineHeight="18px" paddingLeft={'8px'}>
                {'210 6848300'}
              </Box>
            </Link>
          </NextLink>
        </Box>
        <LanguageSelect color={'brand.900'} />
      </Box> */}
      <Box
        position={'absolute'}
        w={'100vw'}
        h={'calc(100vh - 120px)'}
        top={'120px'}
        left={'0'}
        overflowX={'hidden'}
        backgroundColor={'white'}
        zIndex={'1000'}
      >
        <Box overflowY="scroll" className="scrollAreaContainer" height={'auto'}>
          <Box as={'div'} className={'level-generic two'}>
            <Link
              onClick={resetMenu}
              className="menu-item back"
              _focus={{ boxShadow: 'none' }}
              _hover={{ textDecoration: 'none' }}
              paddingRight={'5px'}
              paddingBottom={'15px'}
              fontSize="16px"
              fontWeight="bold"
              lineHeight="20px"
            >
              <Image src={'/assets/arrowCarousel.svg'} width={'7px'} height={'auto'} marginRight={'12px'} />
              {menuData.title}
            </Link>

            <Box borderBottom={'1px solid grey'} paddingBottom={'15px'}>
              <Grid
                templateColumns={{ base: 'repeat(3, 1fr)', sm: 'repeat(4, 1fr)' }}
                gap={2}
                gridRowGap={'35px'}
                paddingBottom={'45px'}
              >
                {menuData.submenu?.map(({ relative, menuImage, title }, index) => {
                  return (
                    <Box key={`submenuItem-${index}`} w="100%" paddingTop="100%" position="relative" textAlign="center">
                      <Box as="div" position="absolute" top="0" left="0" w="100%" h="100%">
                        <NextLink href={relative || '#'} key={index}>
                          <Link>
                            <Image src={menuImage} width={'100%'} height={'100%'} alt={title} />
                          </Link>
                        </NextLink>
                        <Box as="p" textStyle={'menuLevelThreeItemsTitle'} paddingTop={'7px'}>
                          {title}
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Grid>
              <Box textAlign="center">
                <NextLink href={menuData.relative} passHref>
                  <Button variant={'outline'} color={'brand.900'} width={'180px'} as={'a'}>
                    {t('common:view_all_menu')}
                  </Button>
                </NextLink>
              </Box>
            </Box>

            <Box padding={'15px 0'}>
              <Link color={'brand.900'} fontSize="14px" fontWeight="bold" lineHeight="18px">
                New arrivals
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default LevelThreeMobileMenu;
