import Search from 'components/icons/Search';
import useTranslation from 'next-translate/useTranslation';

import { Input, InputLeftElement, InputGroup, InputRightElement, Spinner } from '@chakra-ui/react';

import { CloseIcon } from '@chakra-ui/icons';

export default function SearchInput({ loading, handleSearch, closeAndClearResults }) {
  const { t } = useTranslation('product');

  return (
    <InputGroup>
      <InputLeftElement
        pointerEvents="none"
        children={
          loading ? (
            <Spinner color={'brand.900'} size="md" />
          ) : (
            <Search color={'brand.900'} mt={'5px'} boxSize={'24px '} />
          )
        }
      />
      <Input placeholder={t('search')} size="lg" variant="ghost" onChange={(e) => handleSearch(e.target.value)} />
      <InputRightElement
        pointerEvents="cursor"
        onClick={() => closeAndClearResults()}
        children={<CloseIcon color={'brand.900'} mt={'10px'} />}
      />
    </InputGroup>
  );
}
