import { useState } from 'react';
import { Link, Box, Image } from '@chakra-ui/react';
import NextLink from 'next/link';
import LanguageSelect from '../Header/LanguageSelect';
import LevelThreeMobileMenu from './LevelThreeMobileMenu';
import Container from '../Container';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';

const LevelTwoMobileMenu = ({ menuData, backToLevelOne, customHeight, setIsThird }) => {
  const router = useRouter();
  const [thirdMenu, setThirdMenu] = useState(null);
  const { t } = useTranslation('header');

  return thirdMenu ? (
    <LevelThreeMobileMenu
      setIsThird={setIsThird}
      menuData={thirdMenu}
      customHeight={customHeight}
      resetMenu={() => setThirdMenu(null)}
    />
  ) : (
    <>
      {/* <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        bg={'white'}
        px="12px"
        borderBottom="1px solid #d6006d"
        zIndex={'999'}
      >
        <Box as={'p'} fontSize="14px" fontWeight="normal" lineHeight="18px" color={'brand.900'}>
          {t('contact')}
          <NextLink href={'tel:210 6848300'} passHref prefetch={false}>
            <Link>
              <Box as={'span'} fontSize="14px" fontWeight="bold" lineHeight="18px" paddingLeft={'8px'}>
                {'210 6848300'}
              </Box>
            </Link>
          </NextLink>
        </Box>
        <LanguageSelect color={'brand.900'} />
      </Box> */}
      <Box
        position={'absolute'}
        w={'100vw'}
        h={'calc(100vh - 90px)'}
        top={'90px'}
        left={'0'}
        overflowY={'scroll'}
        overflowX={'hidden'}
        zIndex={'1001'}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          bg={'white'}
          px="12px"
          borderBottom="1px solid #d6006d"
          zIndex={'999'}
        >
          <Box as={'p'} fontSize="14px" fontWeight="normal" lineHeight="18px" color={'brand.900'}>
            {t('contact')}
            <NextLink href={'tel:210 6848300'} passHref prefetch={false}>
              <Link>
                <Box as={'span'} fontSize="14px" fontWeight="bold" lineHeight="18px" paddingLeft={'8px'}>
                  {'210 6848300'}
                </Box>
              </Link>
            </NextLink>
          </Box>
          <LanguageSelect color={'brand.900'} />
        </Box>
        <Box
          position="absolute"
          backgroundColor="#000000"
          opacity=".5"
          display="block"
          w="100%"
          h="100%"
          left="0"
          top="0"
          zIndex="-1"
        ></Box>
        <Box overflowY="scroll" className="scrollAreaContainer">
          <Box as={'div'} className={'level-generic one'} backgroundColor={'white'}>
            <Link
              onClick={backToLevelOne}
              className="menu-item back"
              _focus={{ boxShadow: 'none' }}
              _hover={{ textDecoration: 'none' }}
              paddingRight={'5px'}
              paddingBottom={'15px'}
              fontSize="16px"
              fontWeight="bold"
              lineHeight="20px"
            >
              <Image src={'/assets/arrowCarousel.svg'} width={'7px'} height={'auto'} marginRight={'12px'} />
              {menuData?.title}
            </Link>

            <Box pb="15px">
              <NextLink href={menuData?.relative}>
                <Link paddingRight={'5px'} fontSize="16px" lineHeight="20px">
                  {'All collections'}
                </Link>
              </NextLink>
            </Box>

            {menuData?.submenu?.map(({ title, submenu, cleanUrl, relative }, index) =>
              submenu && submenu.length > 0 ? (
                <Link
                  onClick={() => setThirdMenu({ submenu: submenu, title: title, relative: relative })}
                  position="relative"
                  className="menu-item"
                  key={`menuItem-${index}`}
                  _focus={{ boxShadow: 'none' }}
                  _hover={{ textDecoration: 'none' }}
                  paddingRight={'5px'}
                  marginBottom={'20px'}
                  fontSize="14px"
                  fontWeight="normal"
                  lineHeight="28px"
                  _before={{
                    content: '""',
                    position: 'absolute',
                    backgroundImage: 'url(../assets/arrowMenu.svg)',
                    backgroundSize: 'contain',
                    display: 'block',
                    w: '12px',
                    h: '12px',
                    right: '20px',
                    top: '9px',
                  }}
                >
                  {title}
                </Link>
              ) : (
                <Box>
                  <NextLink href={cleanUrl || '#'} key={index}>
                    <Link
                      position="relative"
                      className="menu-item"
                      _focus={{ boxShadow: 'none' }}
                      _hover={{ textDecoration: 'none' }}
                      paddingRight={'5px'}
                      marginBottom={'20px'}
                      fontSize="14px"
                      fontWeight="normal"
                      lineHeight="28px"
                    >
                      {title}
                    </Link>
                  </NextLink>
                </Box>
              )
            )}
            <Container pos="absolute" left="50%" w="100%" id="footerMenu">
              <Box
                position={'relative'}
                _before={{
                  content: '""',
                  position: 'absolute',
                  backgroundColor: 'brand.900',
                  width: '2000px',
                  height: '100%',
                  left: '-200px',
                  top: '0',
                  zIndex: '-1',
                }}
                padding={'18px 0'}
                textAlign="center"
              >
                <Box padding={'12px'} color="white">
                  <Link
                    fontSize="14px"
                    fontWeight="bold"
                    lineHeight="18px"
                    color="white"
                    onClick={() => {
                      router.push(`/${router.locale}/stores`);
                    }}
                  >
                    {t('stores')}
                  </Link>
                  {' | '}
                  <Link fontSize="14px" fontWeight="bold" lineHeight="18px" color="white">
                    {t('giftCard')}
                  </Link>
                  {' | '}
                  <NextLink passHref prefetch={false} href={'/blog'}>
                    <Link mr={{ sm: '6px', md: '6px', lg: '13px', xl: '26px' }} color={'white'} fontWeight={'bold'}>
                      Blog
                    </Link>
                  </NextLink>
                </Box>
              </Box>
            </Container>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default LevelTwoMobileMenu;
