import { ListItem, Link, Image } from '@chakra-ui/react';

const SocialIconList = ({ url, imageUrl, alt }) => {
  return url ? (
    <ListItem marginRight={{ base: '4px', sm: '8px' }} marginLeft={{ base: '4px', md: '0' }}>
      <Link isExternal href={url} width={35} height={35} display={'inline-block'}>
        <Image src={imageUrl} alt={alt && alt} width={35} height={35} />
      </Link>
    </ListItem>
  ) : null;
};

export default SocialIconList;
