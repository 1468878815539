import { Icon } from '@chakra-ui/react';

const Search = (props) => (
  <Icon viewBox="0 0 18 18" {...props}>
    <path
      fill={props.color}
      d="M17.362 16.166l-4.985-4.986a6.759 6.759 0 001.514-4.267A6.801 6.801 0 007.098.119 6.801 6.801 0 00.304 6.913a6.801 6.801 0 006.794 6.793c1.616 0 3.1-.569 4.267-1.514l4.986 4.985c.14.14.366.14.506 0l.505-.505a.358.358 0 000-.506zm-10.264-3.89a5.37 5.37 0 01-5.364-5.363 5.37 5.37 0 015.364-5.364 5.37 5.37 0 015.363 5.364 5.37 5.37 0 01-5.363 5.363z"
    />
  </Icon>
);

export default Search;
