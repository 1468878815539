import { Box, Text, Button, Link, Image } from '@chakra-ui/react';
import Pin from 'components/icons/Pin';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import NextLink from 'next/link';

const ContactUs = () => {
  const { t } = useTranslation('header');
  const router = useRouter();
  return (
    <>
      <Box flex={1}>
        <Text as={'span'} color={'white'} display="flex" alignItems="center">
          <NextLink href={'tel:210 6848300'} passHref prefetch={false}>
            <Link marginTop="-4px">
              <Image
                src={'/assets/phone-icon-white.svg'}
                display="inline-block"
                marginRight="12px"
                w="18px"
                h={'18px'}
                alt="phone: 210 6848300"
              />
            </Link>
          </NextLink>
          <NextLink href={'tel:210 6848300'} passHref prefetch={false}>
            <Link>
              <Text as={'span'} ml={'8px'} mr={'16px'} color={'white'} fontWeight={'bold'}>
                210 6848300
              </Text>
            </Link>
          </NextLink>

          <Button
            leftIcon={<Pin />}
            variant={'outlineInverted'}
            size={'xs'}
            className={'ammStoresBtn'}
            onClick={() => {
              router.push(`/${router.locale}/stores`);
            }}
          >
            {t('stores')}
          </Button>
        </Text>
      </Box>
    </>
  );
};

export default ContactUs;
