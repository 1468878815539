import { Box, Text } from '@chakra-ui/react';
import Container from '../Container';
import Logo from '../Logo';
import ContactUs from './ContactUs';
import UserNav from './UserNav';
import Menu from '../Menu';

const Header = ({ iconMenuShow, menuAndFooterData }) => {
  return (
    <>
      <Box as={'header'} position={'sticky'} top={'0'} zIndex={'10'} height={'auto'} id="jsAmmHeader">
        <Box background={'brand.900'}>
          <Container justifyContent={'center'} alignItems={'center'} display={'flex'} flexDir="row" height={'90px'}>
            <ContactUs />
            <Logo />
            <UserNav iconMenuShow={iconMenuShow} />
          </Container>
        </Box>
        <Menu iconMenuShow={!iconMenuShow} menuAndFooterData={menuAndFooterData} />
      </Box>
    </>
  );
};

export default Header;
