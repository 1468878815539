import axios from 'axios';
import { apiURL } from 'utils/config';
import { getRealLocale } from 'utils/helpers';

const url = apiURL;

export const getFooterAbout = (locale) => {
  return axios({
    url: `${url}/${getRealLocale(locale)}/api/v1/menu/footer-about`,
    method: 'GET',
  });
};

export const getFooterHelp = (locale) => {
  return axios({
    url: `${url}/${getRealLocale(locale)}/api/v1/menu/footer-help`,
    method: 'GET',
  });
};

export const getFooterMain = (locale) => {
  return axios({
    url: `${url}/${getRealLocale(locale)}/api/v1/menu/main-footer`,
    method: 'GET',
  });
};

export const getAboveFooter = (locale) => {
  return axios({
    url: `${url}/${getRealLocale(locale)}/api/v1/global_sections`,
    method: 'GET',
  });
};
